import {Typography} from '@hconnect/uikit'
import {Box} from '@material-ui/core'
import React from 'react'

interface WidgetSubTitleProps {
  subTitle: string | null
  dataTestId: string
  textColor?:
    | 'textSecondary'
    | 'inherit'
    | 'initial'
    | 'primary'
    | 'secondary'
    | 'textPrimary'
    | 'error'
    | undefined
  customColor?:
    | 'textSuccessMain'
    | 'textError'
    | 'textDisabled'
    | 'textPrimarySoft'
    | 'textSecondarySoft'
    | 'textSecondary'
}

export const WidgetSubTitle: React.FC<WidgetSubTitleProps> = ({
  subTitle,
  dataTestId,
  textColor = 'textSecondary',
  customColor = 'textSecondarySoft'
}) => {
  if (subTitle) {
    return (
      <>
        <Box p={1} />
        <Typography
          variant="caption"
          color={textColor}
          customColor={customColor}
          data-test-id={dataTestId ? `${dataTestId}-title` : ''}
        >
          {subTitle}
        </Typography>
      </>
    )
  }
  return null
}
