import {User} from '@hconnect/apiclient'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import CloseIcon from '@mui/icons-material/Close'
import {useSelector} from 'react-redux'

import {AppState} from '../../Root.store'

import {useInformationBannerState} from './hooks'
import {useBannerStyles} from './styles'

interface InformationBannerProps {
  tableHasData: boolean
  pageName: string
  message: string
}

export const InformationBanner = ({message, pageName, tableHasData}: InformationBannerProps) => {
  const {classes} = useBannerStyles()
  const {isLoading, surveyState, dismissBanner} = useInformationBannerState(pageName)
  const user = useSelector<AppState, User | null>((state) => state.userProfile.userProfile)

  const closeBanner = () => {
    dismissBanner()
    trackEvent('hubTableCheckboxInformationBannerDismissed', {
      product: 'hub',
      country: user?.country || '',
      userId: user?.user_id || '',
      pageName,
      timestamp: new Date()
    })
  }

  if (isLoading || !tableHasData || surveyState?.status !== 'unknown') return null
  return (
    <div className={classes.container} data-test-id="tableInformationBanner">
      {message}
      <CloseIcon
        onClick={closeBanner}
        className={classes.closeButton}
        data-test-id="tableInformationBannerClose"
      />
    </div>
  )
}
