import {useQuery} from 'react-query'

import {api} from '../../App.global'

export const useSupplierNamesFromCustomer = (customerId: string) => {
  return useQuery(
    ['suppliers', {customerId}],
    async () => {
      if (!customerId) return

      const res = await api.get(`customers/${customerId}/suppliers`, {})
      return res.data
    },
    {
      enabled: !!customerId // Only run the query if customerId is truthy
    }
  )
}
