import {humanReadableDateRange} from '@hconnect/uikit'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import {Box, Button} from '@mui/material'
import currencyFormatter from 'currency-formatter'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import {ReactComponent as BulbIcon} from '../../../Assets/c60_page_assets/bulbIcon.svg'
import {Invoice} from '../../../Pages/Finance/Invoices/Invoice.types'
import {AppState} from '../../../Root.store'
import {useStyles} from '../styles'

interface Type {
  onHover: () => void
  onButtonClick: () => void
  totalGrossValue: number
}
export const ContextualC60Lead: React.FC<Type> = ({onHover, onButtonClick, totalGrossValue}) => {
  const {classes} = useStyles()
  const {
    i18n: {language},
    t
  } = useTranslation()
  const isDetailsPage = window.location.pathname.includes('/invoices/')
  const invoices = useSelector<AppState, Invoice[]>((state) =>
    state.overview.invoice.items.slice(0, 5)
  )
  const {filters, defaultFilters} = useSelector<AppState, any>((state) => state.finance.invoices)

  const amount = currencyFormatter.format(totalGrossValue, {
    code: invoices[0]?.invoiceCurrency,
    locale: language
  })

  const getRange = () => {
    if (!isDetailsPage) {
      if (filters && filters.date) {
        const {startDate, endDate} = filters.date
        return humanReadableDateRange(startDate as Date, endDate as Date)
      }
      if (defaultFilters && defaultFilters.date) {
        const {startDate, endDate} = defaultFilters.date
        return humanReadableDateRange(startDate as Date, endDate as Date)
      }
    }
  }

  return (
    <Box
      className={`${classes.c60Container} ${isDetailsPage && classes.detailsPageStyles}`}
      onMouseEnter={() => onHover()}
      data-test-id="contextual-c60-lead-banner"
    >
      {!isDetailsPage && (
        <div className={classes.description} data-test-id="contextual-c60-lead-description">
          {t('lead.c60.descriptionSection1')}
          <div className={classes.amount}>{amount}</div>
          <div>
            {t('lead.c60.descriptionSection2', {
              dateRange: getRange()
            })}
          </div>
        </div>
      )}
      <div className={classes.learnMore} data-test-id="contextual-c60-lead-learnmore">
        {t('lead.c60.learnMoreDescription')}
        <Button
          variant="text"
          startIcon={<ArrowForwardIcon />}
          onClick={(event) => {
            event.preventDefault()
            onButtonClick()
          }}
          data-test-id="contextual-c60-lead-link"
        >
          {t('lead.c60.learnMore')}
        </Button>
      </div>
      <div className={classes.hint} data-test-id="contextual-c60-lead-hint">
        <BulbIcon className={classes.bulbIcon} />
        {t('lead.c60.contextualC60Description')}
      </div>
    </Box>
  )
}
