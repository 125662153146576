import {Typography} from '@hconnect/uikit'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import {Box, Collapse, Paper} from '@mui/material'
import React from 'react'

import type {TabCardType} from '../types'

import {BoldText} from './Typography'

interface Type {
  tab: TabCardType
  active: boolean
  setActive: React.Dispatch<React.SetStateAction<TabCardType>>
  onTabClick: (tab: string) => void
  title: string
  subtitle: string
  variant?: string
  variantText?: string
  smallScreen?: boolean
}

export const TabCard: React.FC<Type> = ({
  tab,
  active,
  setActive,
  onTabClick,
  title,
  subtitle,
  variant,
  variantText,
  smallScreen = false
}) => (
  <Paper
    elevation={1}
    onClick={() => {
      setActive(tab)
      onTabClick(tab.tabId)
    }}
    sx={{
      bgcolor: active ? 'rgba(1, 106, 212, 0.04)' : 'transparent',
      borderBottom: '1px solid #EAEDF0',
      borderRadius: 0
    }}
  >
    <Box
      data-test-id={`tab-card-${tab.tabId}`}
      display="flex"
      flexDirection="row"
      py={2}
      px={smallScreen ? 2 : 3}
    >
      <Box data-test-id="tab-card-content" flex={80} display="flex" flexDirection="column">
        {variant && variantText ? (
          <Box data-test-id={`tab-card-category-${tab.tabId}`} color={variant}>
            <BoldText variant="body2" align="left" color="inherit" gutterBottom>
              {variantText}
            </BoldText>
          </Box>
        ) : null}
        <Box data-test-id={`tab-card-title-${tab.tabId}`}>
          <BoldText variant="body1" align="left" gutterBottom>
            {title}
          </BoldText>
        </Box>
        <Collapse in={!(variant && variantText && !active)}>
          <Box data-test-id={`tab-card-subtitle-${tab.tabId}`}>
            <Typography variant="body2" align="left">
              {subtitle}
            </Typography>
          </Box>
        </Collapse>
      </Box>
      <Box
        data-test-id={`tab-card-arrow-${tab.tabId}`}
        flex={20}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {active ? (
          <KeyboardArrowRightIcon data-test-id="arrow-right" />
        ) : (
          <KeyboardArrowDownIcon data-test-id="arrow-down" />
        )}
      </Box>
    </Box>
  </Paper>
)
