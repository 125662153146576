import {makeStyles} from 'tss-react/mui'
import {Theme} from '@mui/material'

export const styles = makeStyles()((theme: Theme) => ({
  etaContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    border: '1px solid #e8ecf0',
    borderRadius: '4px'
  },
  etaTitle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '12px',
    '& svg': {
      color: '#54708c'
    }
  },
  container: {
    display: 'flex'
  },
  etaSubContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: '12px 0'
  },
  etaSubContainerWithBorder: {
    paddingLeft: '12px',
    borderLeft: '1px solid #e8ecf0'
  },
  etaSubContainerTitle: {
    fontSize: '12px',
    color: '#54708c'
  },
  etaSubContainerValue: {
    fontWeight: 600,
    color: '#00274d'
  },
  etaStatus: {
    fontSize: '12px'
  },
  onTime: {
    color: '#31ca72'
  },
  delayed: {
    color: '#da0901'
  },
  unknown: {
    color: '#54708c'
  }
}))
