import {trackEvent} from '@hconnect/common/logging/Analytics'
import {LayoutConstants, Typography} from '@hconnect/uikit'
import {Box, Button, CircularProgress, Collapse} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import InfoRoundedIcon from '@material-ui/icons/InfoRounded'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {
  EXPORT_FILENAME_INVOICES,
  EXPORT_FILENAME_ORDERS,
  EXPORT_FILENAME_TICKETS
} from '../App.constants'
import {
  ApiDownloadRequestStatuses,
  JobEndpoint,
  JobType
} from '../AsyncJobQueue/AsyncJobQueue.types'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'sticky',
    width: '100%'
  },
  progress: {
    verticalAlign: 'middle',
    marginRight: theme.spacing()
  },
  colorPrimary: {
    color: 'red'
  },
  icon: {
    marginRight: 10
  }
}))

const InfoIcon: React.FC = () => {
  const classes = useStyles()
  return (
    <InfoRoundedIcon
      color="primary"
      className={classes.icon}
      classes={{colorPrimary: classes.colorPrimary}}
    />
  )
}

interface Props {
  deleteJob?: (id: string, name?: string) => void
  job: JobType
  reportJob: (id: string) => void
  reportedJobs: string[]
  apiStatus: ApiDownloadRequestStatuses
}

const SLOW_DOWNLOAD_TIMEOUT = 10000

// eslint-disable-next-line complexity
const JobBanner: React.FC<Props> = ({deleteJob, job, reportJob, reportedJobs, apiStatus}) => {
  const classes = useStyles()

  const {t} = useTranslation()

  const [isDownloadSlow, setIsDownloadSlow] = useState<boolean>(false)

  useEffect(() => {
    setTimeout(() => {
      setIsDownloadSlow(true)
    }, SLOW_DOWNLOAD_TIMEOUT)
  }, [job])

  useEffect(() => {
    if (isDownloadSlow) {
      if (
        job.downloadType === 'invoiceDeliveryExport' ||
        job.downloadType === 'orderDeliveryExport'
      ) {
        trackEvent('hubExportSlow', {
          product: 'hub',
          jobId: job.jobId
        })
      }
      if (
        job.downloadType === 'bulkInvoiceDeliveryDownload' ||
        job.downloadType === 'bulkOrderDeliveryDownload'
      ) {
        trackEvent('hubBulkDownloadSlow', {
          product: 'hub',
          jobId: job.jobId
        })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDownloadSlow])

  interface GetIconOrProgressType {
    status: ApiDownloadRequestStatuses
  }
  const GetIconOrProgress: React.FC<GetIconOrProgressType> = ({status}) => {
    switch (status) {
      case ApiDownloadRequestStatuses.partiallyFailed:
        return <InfoIcon />

      case ApiDownloadRequestStatuses.failed:
        return <InfoIcon />

      case ApiDownloadRequestStatuses.cancelled:
        return <InfoIcon />

      default:
        return (
          <CircularProgress
            size={24}
            className={classes.progress}
            data-test-id="job-banner-loader"
          />
        )
    }
  }

  const entityFromJob = (job: JobType): string => {
    if (job.name.startsWith(EXPORT_FILENAME_ORDERS)) {
      return t('asyncjobqueue.entity.orders')
    }
    if (job.name.startsWith(EXPORT_FILENAME_INVOICES)) {
      return t('asyncjobqueue.entity.invoices')
    }
    if (job.name.startsWith(EXPORT_FILENAME_TICKETS)) {
      return t('asyncjobqueue.entity.tickets')
    }
    return job.name
  }

  return (
    <Box
      data-test-id="job-banner"
      display="flex"
      justifyContent="space-between"
      width={LayoutConstants.DEFAULT_CONTENT_WIDTH}
      paddingLeft={2}
      paddingRight={2}
      paddingTop={1.5}
      m="auto"
      minHeight="48px"
      className={classes.root}
    >
      <Box display="flex" flexDirection="column" width="100%">
        <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
          <Box display="flex" alignItems="center">
            {job.name === 'downloadFailed' ? (
              <>
                <GetIconOrProgress
                  status={ApiDownloadRequestStatuses.failed}
                  data-test-id={`job-banner-icon-${job.status}`}
                />
                <Box ml="auto" mr="auto">
                  <Typography variant="body2" color="textPrimary" component="span">
                    {t('asyncjobqueue.downloadFailed')}
                  </Typography>
                </Box>
              </>
            ) : (
              <GetIconOrProgress status={apiStatus} data-test-id={`job-banner-icon-${status}`} />
            )}
            {job.type === JobEndpoint.Export ? (
              <Typography variant="body2" color="textPrimary" component="span">
                {t(`asyncjobqueue.status.${job.status}`, {entity: entityFromJob(job)})}
              </Typography>
            ) : (
              job.name !== 'downloadFailed' && (
                <Typography variant="body2" color="textPrimary" component="span">
                  {t('asyncjobqueue.file')} {job.name} {t('asyncjobqueue.documentDownload')}
                </Typography>
              )
            )}

            {isDownloadSlow &&
            job.id &&
            job.name !== 'downloadFailed' &&
            job.status !== ApiDownloadRequestStatuses.timedout &&
            !reportedJobs.includes(job.id) ? (
              <Button
                variant="text"
                color="primary"
                onClick={() => {
                  setIsDownloadSlow(false)
                  job.id && reportJob(job.id)
                  if (
                    job.downloadType === 'invoiceDeliveryExport' ||
                    job.downloadType === 'orderDeliveryExport'
                  ) {
                    trackEvent('hubExportReportedSlow', {
                      product: 'hub',
                      jobId: job.jobId
                    })
                  }
                  if (
                    job.downloadType === 'bulkInvoiceDeliveryDownload' ||
                    job.downloadType === 'bulkOrderDeliveryDownload'
                  ) {
                    trackEvent('hubBulkDownloadReportedSlow', {
                      product: 'hub',
                      jobId: job.jobId
                    })
                  }
                }}
              >
                {t('asyncjobqueue.slowDownload')}
              </Button>
            ) : null}
          </Box>
          {job.name === 'downloadFailed' ? (
            <Button
              data-test-id="job-banner-close-button"
              variant="text"
              color="primary"
              onClick={() => {
                if (job.name === 'downloadFailed') {
                  deleteJob && deleteJob('1', 'downloadFailed')
                }
              }}
            >
              {t('asyncjobqueue.close')}
            </Button>
          ) : null}
          {deleteJob && job.status && job.name !== 'downloadFailed' ? (
            <Button
              variant="text"
              color="primary"
              onClick={() => {
                if (
                  job.downloadType === 'invoiceDeliveryExport' ||
                  job.downloadType === 'orderDeliveryExport'
                ) {
                  trackEvent('hubExportCancel', {
                    product: 'hub',
                    jobId: job.jobId
                  })
                }
                if (
                  job.downloadType === 'bulkInvoiceDeliveryDownload' ||
                  job.downloadType === 'bulkOrderDeliveryDownload'
                ) {
                  trackEvent('hubBulkDownloadCancel', {
                    product: 'hub',
                    jobId: job.jobId
                  })
                }
                deleteJob && job.id && deleteJob(job.id)
              }}
            >
              {[
                ApiDownloadRequestStatuses.partiallyFailed,
                ApiDownloadRequestStatuses.failed
              ].indexOf(apiStatus) > 0
                ? t('asyncjobqueue.close')
                : t('asyncjobqueue.cancel')}
            </Button>
          ) : null}
        </Box>
        <Collapse in={job.id ? reportedJobs.includes(job.id) : false}>
          <Box display="flex" flexDirection="row" paddingBottom={1} paddingLeft={4}>
            <Typography variant="caption" customColor="textPrimarySoft">
              {t('asyncjobqueue.downloadReport')}
            </Typography>
          </Box>
        </Collapse>
      </Box>
    </Box>
  )
}

export default JobBanner
