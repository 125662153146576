import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {CustomerQuestion} from '../types'

export const useCustomerFeedbackSurveyQuestions = () => {
  const {t} = useTranslation()

  const questions: CustomerQuestion[] = useMemo(
    () => [
      {
        id: 1,
        statement: t('customerFeedbackSurvey.question1Statement'),
        comment: t('customerFeedbackSurvey.question1Comment')
      },
      {
        id: 2,
        statement: t('customerFeedbackSurvey.question2Statement'),
        comment: t('customerFeedbackSurvey.question2Comment')
      }
    ],
    [t]
  )

  return questions
}
