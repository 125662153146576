import {BusinessLineType, BusinessLineTypes} from '../../common/types'
import {Shippings} from '../../Pages/Finance/Invoices/Delivery.types'
import {ModeOfTransport, ModeOfTransportType, ShippingType} from '../../Pages/Order/Order.types'

export enum ResolveModeOfTransport {
  DeliverMixerTruck = 'DeliverMixerTruck',
  DeliverDumpTruck = 'DeliverDumpTruck',
  DeliverBulkCarrier = 'DeliverBulkCarrier',
  DeliverTruck = 'DeliverTruck',
  DeliverShip = 'DeliverShip',
  DeliverTrain = 'DeliverTrain',
  PickupMixerTruck = 'PickupMixerTruck',
  PickupDumpTruck = 'PickupDumpTruck',
  PickupBulkCarrier = 'PickupBulkCarrier',
  PickupTruck = 'PickupTruck'
}

export type ResolveModeOfTransportType = keyof typeof ResolveModeOfTransport

export const resolveModeOfTransport = (
  modeOfTransport: ModeOfTransportType,
  shippingType: Shippings,
  businessLine: BusinessLineTypes,
  uom: string
  // eslint-disable-next-line max-params
): ResolveModeOfTransportType => {
  // TODO: resolve icons, tooltips and color
  if (modeOfTransport === ModeOfTransport.Truck) {
    switch (businessLine) {
      case BusinessLineType.RMC:
        return shippingType === ShippingType.Deliver
          ? ResolveModeOfTransport.DeliverMixerTruck
          : ResolveModeOfTransport.PickupMixerTruck

      case BusinessLineType.AGG:
        return shippingType === ShippingType.Deliver
          ? ResolveModeOfTransport.DeliverDumpTruck
          : ResolveModeOfTransport.PickupDumpTruck

      case BusinessLineType.CEM:
        if (uom === 'BAG') {
          return shippingType === ShippingType.Deliver
            ? ResolveModeOfTransport.DeliverTruck
            : ResolveModeOfTransport.PickupTruck
        }
        return shippingType === ShippingType.Deliver
          ? ResolveModeOfTransport.DeliverBulkCarrier
          : ResolveModeOfTransport.PickupBulkCarrier

      default:
        return ResolveModeOfTransport.DeliverBulkCarrier
    }
  } else if (modeOfTransport === ModeOfTransport.Ship) {
    return ResolveModeOfTransport.DeliverShip
  } else if (modeOfTransport === ModeOfTransport.Train) {
    return ResolveModeOfTransport.DeliverTrain
  } else {
    return ResolveModeOfTransport.DeliverDumpTruck
  }
}
