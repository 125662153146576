import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent
} from '@mui/material'
import React, {useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {MenuProps, useMultiSelectDropdownStyles} from './styles'

interface MultiSelectDropdownType {
  items: string[]
  onClose: (selectedList: string[]) => void
  multiSelectLabel: string
  translationItemPrefix: string
  selectAllLabel: string
  dataTestId?: string
  selectedOptionsList?: string[]
}

function getStyles(optionKey: string, selectedOptions: string[]) {
  if (selectedOptions.indexOf(optionKey) === -1)
    return {
      color: '#00374d',
      backgroundColor: '#f7f7f7'
    }
  return {
    color: '#000',
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  }
}

const MultiSelectDropdown: React.FC<MultiSelectDropdownType> = ({
  items,
  selectedOptionsList,
  multiSelectLabel,
  selectAllLabel,
  translationItemPrefix = '',
  dataTestId = `multiselect-dropdown-${multiSelectLabel}`,
  onClose
}) => {
  const {t} = useTranslation()
  const [selectedOptions, setOptionsList] = useState<string[]>(selectedOptionsList || items)
  const {classes} = useMultiSelectDropdownStyles()
  const inputLabelRef = useRef<HTMLLabelElement>(null)
  const isAllSelected = selectedOptions.length === (items && items.length)

  useEffect(() => {
    setOptionsList(selectedOptionsList || selectedOptions)
  }, [selectedOptionsList])

  const handleChange = (event: SelectChangeEvent<typeof selectedOptions>) => {
    event.stopPropagation()
    event.preventDefault()
    const {
      target: {value}
    } = event

    if (value[value.length - 1] !== 'all') {
      setOptionsList(value as string[])
      return
    }

    const updatedList = isAllSelected ? [] : items
    setOptionsList(updatedList)
  }
  const handleClose = () => {
    // remove focus class from input fieldset when no data is selected
    selectedOptions.length === 0 && inputLabelRef.current?.classList.remove('MuiInputLabel-shrink')
    onClose(selectedOptions)
  }

  const statusCount = (selected) => {
    return (
      <span>
        {translationItemPrefix ? t(`${translationItemPrefix}.${selected[0]}`) : selected[0]}
        {selected.length > 1 && (
          <span className={classes.defaultValueCount}>{` + ${selected.length - 1}`}</span>
        )}
      </span>
    )
  }

  return (
    <div>
      <FormControl>
        <InputLabel
          id="multiselect-dropdown-label"
          className={classes.statusLabel}
          ref={inputLabelRef}
          data-test-id={`${dataTestId}-label`}
        >
          {multiSelectLabel}
        </InputLabel>
        <Select
          data-test-id={dataTestId}
          labelId="multiselect-dropdown-label"
          multiple
          value={selectedOptions}
          displayEmpty
          onChange={handleChange}
          input={<OutlinedInput />}
          MenuProps={MenuProps}
          className={classes.multiSelectBox}
          IconComponent={KeyboardArrowDownIcon}
          onClose={handleClose}
          renderValue={(selected) => (
            <span className={classes.defaultValue}>
              {isAllSelected && selectAllLabel}
              {!isAllSelected &&
                selected.length > 0 &&
                selected.length < items.length &&
                statusCount(selected)}
            </span>
          )}
        >
          <MenuItem
            key="allItems"
            value="all"
            style={{
              color: isAllSelected ? '#000' : '#00374d',
              backgroundColor: isAllSelected ? 'rgba(0, 0, 0, 0.04)' : '#f7f7f7'
            }}
            className={classes.statusItems}
            data-test-id={`${dataTestId}-all`}
          >
            <Checkbox checked={isAllSelected} className={classes.checkbox} />
            {selectAllLabel}
          </MenuItem>
          {items.map((optionKey) => (
            <MenuItem
              key={optionKey}
              value={optionKey}
              style={getStyles(optionKey, selectedOptions)}
              className={classes.statusItems}
              data-test-id={`multiselect-filter-${optionKey}`}
            >
              <Checkbox
                checked={selectedOptions.indexOf(optionKey) > -1}
                className={classes.checkbox}
                data-test-id={`multiselect-filter-checkbox-${optionKey}`}
              />
              {translationItemPrefix ? t(`${translationItemPrefix}.${optionKey}`) : optionKey}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

export default MultiSelectDropdown
