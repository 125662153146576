import {User} from '@hconnect/apiclient'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined'
import PolicyOutlinedIcon from '@mui/icons-material/PolicyOutlined'
import SourceOutlinedIcon from '@mui/icons-material/SourceOutlined'
import {Box, Link as LinkComponent, MenuItem, Typography} from '@mui/material'
import {Link} from 'react-router-dom'

import React from 'react'
import {useTranslation} from 'react-i18next'

import {getOssAttributionLink} from '../../common/appInfo/utilities'
import {useBranding} from '../../Organisms/Branding'
import {useFeaturesState} from '../../Organisms/Features'
import {ROUTE as DisclaimerRoute} from '../../Pages/Legal/Disclaimer'
import {ROUTE as ImprintRoute} from '../../Pages/Legal/Imprint'
import {ROUTE as PrivacyRoute} from '../../Pages/Legal/Privacy'
import {ROUTE as TermsOfUseRoute} from '../../Pages/Legal/Terms'
import {LegalLinkType} from '../LegalLinks'

import {useAccountMenuStyles} from './AccountMenuStyles'
import {SubMenu} from './SubMenu'

interface ITermsAndConditions {
  user: User | null
  openSubMenu: (menu: JSX.Element | null) => void
}

export const LegalTermsAndConditions: React.FC<ITermsAndConditions> = ({user, openSubMenu}) => {
  const {t} = useTranslation()
  const {
    country: countryFromBranding,
    contactEmailHref,
    termsAndConditionsOfSale,
    termsOfSales,
    safetyInstructionsAndGeneralTermsOfSaleRMC,
    safetyInstructionsAndGeneralTermsOfSaleAGG
  } = useBranding()
  const country = user?.country || countryFromBranding
  const classes = useAccountMenuStyles()

  const {getResolutionByNameAndConstraint, normalized} = useFeaturesState()

  const termsAndConditions: LegalLinkType[] = [
    ...(getResolutionByNameAndConstraint('Disclaimer', normalized, 'countryId').enabled.includes(
      country as string
    )
      ? [
          {
            key: 'disclaimer',
            title: t('accountMenu.legal.disclaimer'),
            endpoint: DisclaimerRoute,
            isInternal: true,
            icon: <ArticleOutlinedIcon className={classes.legalIcon} />
          }
        ]
      : []),
    {
      key: 'termsofuse',
      title: t('accountMenu.legal.termsofuse'),
      endpoint: TermsOfUseRoute,
      isInternal: true,
      icon: <ArticleOutlinedIcon className={classes.legalIcon} />
    },
    ...(getResolutionByNameAndConstraint(
      'TermsAndConditionsOfSale',
      normalized,
      'countryId'
    ).enabled.includes(country as string)
      ? [
          {
            key: 'termsconditionsofsale',
            title: t('accountMenu.legal.saleConditions'),
            endpoint: termsAndConditionsOfSale,
            isInternal: false,
            icon: <ArticleOutlinedIcon className={classes.legalIcon} />
          }
        ]
      : []),
    ...(getResolutionByNameAndConstraint('TermsOfSales', normalized, 'countryId').enabled.includes(
      country as string
    )
      ? [
          {
            key: 'termsOfSales',
            title: t('accountMenu.legal.termsOfSales'),
            endpoint: termsOfSales,
            isInternal: false,
            icon: <ArticleOutlinedIcon className={classes.legalIcon} />
          }
        ]
      : []),
    ...(getResolutionByNameAndConstraint(
      'DifferentLinkForSafetyInstructionsAndGeneralTermsOfSalesForAGGAndRMC',
      normalized,
      'countryId'
    ).enabled.includes(country as string)
      ? [
          {
            key: 'safetyInstructionsAndGeneralTermsOfSaleRMC',
            title: t('accountMenu.legal.safetyInstructionsAndGeneralTermsOfSaleRMC'),
            endpoint: safetyInstructionsAndGeneralTermsOfSaleRMC,
            isInternal: false,
            icon: <ArticleOutlinedIcon className={classes.legalIcon} />
          },
          {
            key: 'safetyInstructionsAndGeneralTermsOfSaleAGG',
            title: t('accountMenu.legal.safetyInstructionsAndGeneralTermsOfSaleAGG'),
            endpoint: safetyInstructionsAndGeneralTermsOfSaleAGG,
            isInternal: false,
            icon: <ArticleOutlinedIcon className={classes.legalIcon} />
          }
        ]
      : []),
    {
      key: 'privacy',
      title: t('accountMenu.legal.privacy'),
      endpoint: PrivacyRoute,
      isInternal: true,
      icon: <ArticleOutlinedIcon className={classes.legalIcon} />
    },
    ...(getResolutionByNameAndConstraint('Imprint', normalized, 'countryId').enabled.includes(
      country as string
    )
      ? [
          {
            key: 'imprint',
            title: t('accountMenu.legal.imprint'),
            endpoint: ImprintRoute,
            isInternal: true,
            icon: <ArticleOutlinedIcon className={classes.legalIcon} />
          }
        ]
      : []),
    {
      key: 'contact',
      title: t('accountMenu.legal.contact'),
      endpoint: contactEmailHref,
      isInternal: false,
      onClick: () => {
        trackEvent('hubContact', {country: user?.country})
      },
      icon: <MailOutlineOutlinedIcon className={classes.legalIcon} />
    },
    {
      key: 'openSourceSoftwareAttribution',
      title: t('accountMenu.legal.openSourceSoftwareAttribution'),
      endpoint: getOssAttributionLink(),
      isInternal: false,
      icon: <SourceOutlinedIcon className={classes.legalIcon} />
    }
  ]

  const subMenuItems = termsAndConditions.map(
    ({isInternal, key, title, endpoint, onClick, target = '_blank', icon}) => {
      if (isInternal) {
        return endpoint ? (
          <LinkComponent
            key={key}
            role="link"
            component={Link}
            variant="body2"
            color="secondary"
            to={endpoint}
            underline="none"
            data-test-id={`terms-and-conditions-${key}`}
          >
            <MenuItem
              className={classes.item}
              onClick={() => {
                onClick && onClick()
                openSubMenu(null)
              }}
              data-test-id={`sub-menu-item-${key}`}
            >
              {icon}
              {title}
            </MenuItem>
          </LinkComponent>
        ) : (
          <MenuItem
            key={key}
            className={classes.item}
            onClick={() => {
              onClick && onClick()
            }}
            data-test-id={`sub-menu-item-${key}`}
          >
            {icon}
            {title}
          </MenuItem>
        )
      }

      // when link is external
      return (
        <LinkComponent
          key={key}
          variant="body2"
          color="secondary"
          href={endpoint}
          onClick={onClick}
          target={target}
          underline="none"
          rel="noopener noreferrer"
          data-test-id={`terms-and-conditions-${key}`}
        >
          <MenuItem className={classes.item}>
            {icon}
            {title}
          </MenuItem>
        </LinkComponent>
      )
    }
  )

  const menuItem = (
    <Box sx={{display: 'flex'}}>
      <PolicyOutlinedIcon className={classes.icon} />
      <Typography>{t('accountMenu.legal.title')}</Typography>
    </Box>
  )
  return (
    <SubMenu
      title={t('accountMenu.legal.title')}
      icon={<PolicyOutlinedIcon className={classes.icon} />}
      menuItem={menuItem}
      subMenuItems={subMenuItems}
      openSubMenu={openSubMenu}
      dividers
      dataTestId="terms-and-conditions-submenu"
    />
  )
}
