import {Typography} from '@hconnect/uikit'
import {Box, makeStyles, Button} from '@material-ui/core'
import {Error} from '@material-ui/icons'
import React from 'react'
import {useTranslation} from 'react-i18next'

interface ErrorBannerProps {
  isVisible: boolean
  label: string
  'data-test-id'?: string
  handleClick: () => void
}

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'rgba(255, 79, 0, .1)',
    height: theme.spacing(7),
    padding: `${theme.spacing(1.5)}px`
  },

  spin: {
    backgroundColor: 'red',
    animation: 'spin 4s linear infinite',
    transform: 'rotate(180deg)'
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(180deg)'
    }
  }
}))

export const ErrorBanner: React.FC<ErrorBannerProps> = ({
  isVisible,
  label,
  handleClick,
  'data-test-id': dataTestId
}) => {
  const classes = useStyles()
  const {t} = useTranslation()
  if (!isVisible) {
    return null
  }

  return (
    <Box px={4} py={1}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        className={classes.container}
      >
        <Box display="flex" alignItems="center">
          <Error />
          <Box p={1} />
          <Typography variant="h4" color="textPrimary" data-test-id={dataTestId}>
            {label}
          </Typography>
        </Box>

        <Box p={1} />
        <Button
          data-test-id={`${dataTestId}-button`}
          onClick={() => {
            handleClick()
          }}
          color="primary"
        >
          {t('dismiss')}
        </Button>
      </Box>
    </Box>
  )
}
