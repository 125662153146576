import {Button} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'

const useStyles = makeStyles(() => ({
  colorSecondary: {
    color: 'rgba(255, 255, 255, 0.96)',
    border: '1px solid rgb(178, 195, 201)',
    '&:hover': {
      background: 'transparent',
      border: '1px solid rgb(255, 255, 255)'
    }
  }
}))

interface LoadMoreButtonProps {
  isFetching: boolean
  onClick(): void
  onLight?: boolean
}

export const LoadMoreButton: React.FC<LoadMoreButtonProps> = ({
  onClick,
  isFetching,
  onLight = false,
  ...otherProps
}) => {
  const classes = useStyles()
  const {t} = useTranslation()
  return (
    <Button
      data-test-id="pagination-button"
      classes={{
        outlinedSecondary: classes.colorSecondary
      }}
      disabled={isFetching}
      variant="outlined"
      color={onLight ? 'primary' : 'secondary'}
      onClick={onClick}
      {...otherProps}
    >
      {t('invoice.moreResult')}
    </Button>
  )
}
