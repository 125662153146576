import {Box, Divider, Typography} from '@material-ui/core'
import React from 'react'

interface CustomHeaderProps {
  title: string
  noDivider?: boolean
  color?: 'textPrimary' | 'secondary'
  children?: React.ReactNode
}

export const CustomHeader = ({
  title,
  noDivider,
  children,
  color = 'textPrimary'
}: CustomHeaderProps) => {
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          color={color}
          variant="h4"
          style={{marginBottom: 12, marginTop: 12, fontWeight: 600}}
        >
          {title}
        </Typography>
        <Box>{children}</Box>
      </Box>
      {!noDivider && <Divider style={{marginBottom: 12}} />}
    </>
  )
}
