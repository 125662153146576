import {lighten} from '@mui/system'
import {makeStyles} from 'tss-react/mui'

import {Order} from '../Pages/Order/Order.types'

const useStyles = makeStyles()(() => ({
  bar: {
    display: 'flex',
    backgroundColor: lighten('#31ca72', 0.75),
    height: '6px'
  },
  barParts: {
    height: '6px'
  },
  deliveredPart: {
    backgroundColor: '#31ca72'
  },
  inTransitPart: {
    backgroundColor: '#07374d'
  }
}))
interface IMiniProgressBar {
  order: Order
}
const PERCENTAGE_FACTOR = 100
const calculateBarPercentage = (qty: number, totalQty: number) => {
  const value = (qty / totalQty) * PERCENTAGE_FACTOR
  return qty > totalQty ? PERCENTAGE_FACTOR : value
}

const MiniProgressBar = ({order}: IMiniProgressBar) => {
  const {classes} = useStyles()
  const {quantity} = order.lineItems[0]
  const {deliveredQuantity = 0, shippedQuantity = 0, arrivedQuantity = 0} = order.deliveryProgress
  const delivered = calculateBarPercentage(deliveredQuantity, quantity)
  const inTransit = calculateBarPercentage(shippedQuantity - arrivedQuantity, quantity)

  const progressBarParts = [
    {class: 'deliveredPart', value: `${delivered}%`},
    {class: 'inTransitPart', value: `${inTransit}%`}
  ]

  return (
    <div className={classes.bar} data-test-id={`miniProgressBar-${order.orderId}`}>
      {progressBarParts.map((bar, index) => {
        return (
          <div
            key={index}
            data-test-id={`miniBar-${bar.class}-${order.orderId}`}
            className={`${classes.barParts} ${classes[bar.class]}`}
            style={{
              width: bar.value
            }}
          />
        )
      })}
    </div>
  )
}

export default MiniProgressBar
