import {User} from '@hconnect/apiclient'
import CloseIcon from '@mui/icons-material/Close'
import ContentPasteIcon from '@mui/icons-material/ContentPaste'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import ExploreIcon from '@mui/icons-material/Explore'
import MarkAsUnreadOutlinedIcon from '@mui/icons-material/MarkAsUnreadOutlined'
import MessageIcon from '@mui/icons-material/Message'
import PeopleIcon from '@mui/icons-material/People'
import {LoadingButton} from '@mui/lab'
import {Checkbox, IconButton, Link, Typography} from '@mui/material'
import {StyledEngineProvider} from '@mui/material/styles'
import React, {useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {Link as RouterLink} from 'react-router-dom'

import CustomerPanelSvg from '../../../Assets/customerPanel/customerPanel.svg'
import {useFeaturesState} from '../../../Organisms/Features'
// eslint-disable-next-line import/no-cycle
import {AppState} from '../../../Root.store'
import {CustomHeader} from '../../CustomHeader'
import {useImprovementProgrammeMutation} from '../hooks'

import {usePanelStyles} from './styles'
import {useTrackEventFeedbackProgramme} from '../hooks/useTrackEventFeedbackProgramme'
import {CustomerFeedbackProgrammeAction} from '../../../common/constants'
import {useRoles} from '../../../Roles'

interface CustomerFeedbackPanelProps {
  close: (event: Event | React.SyntheticEvent) => void
  isSubscribingProcessOn?: (val: boolean) => void
  isFromProfile?: boolean
  entryPoint?: string
  children?: React.ReactNode
}

const PanelRow = ({text, icon}) => {
  const {classes} = usePanelStyles()
  return (
    <div className={classes.panelRow}>
      <div className={classes.rowIcon}>{icon}</div>
      <Typography className={classes.rowText}>{text}</Typography>
    </div>
  )
}

export const FeedbackIntroductionSection = () => {
  const {t} = useTranslation()
  const {classes} = usePanelStyles()
  return (
    <StyledEngineProvider injectFirst>
      <Typography className={classes.sectionHeader}>
        {t('customerFeedbackPanel.section1Header')}
      </Typography>
      <PanelRow text={t('customerFeedbackPanel.section1Item1')} icon={<MessageIcon />} />
      <PanelRow text={t('customerFeedbackPanel.section1Item2')} icon={<ExploreIcon />} />

      <Typography className={classes.sectionHeader}>
        {t('customerFeedbackPanel.section2Header')}
      </Typography>
      <PanelRow text={t('customerFeedbackPanel.section2Item1')} icon={<PeopleIcon />} />
      <PanelRow text={t('customerFeedbackPanel.section2Item2')} icon={<EventAvailableIcon />} />
      <PanelRow text={t('customerFeedbackPanel.section2Item3')} icon={<ContentPasteIcon />} />
    </StyledEngineProvider>
  )
}

export const ReferPrivacyPolicy = () => {
  const {t} = useTranslation()
  const {classes} = usePanelStyles()

  return (
    <Typography className={classes.rowText}>
      <Trans t={t} i18nKey="customerFeedbackPanel.privacyPolicyDisclaimer">
        Refer to the
        <Link
          color="primary"
          role="link"
          to="/privacy?noNav=true"
          component={RouterLink}
          target="__blank"
        >
          privacy policy
        </Link>
        , for further details.
      </Trans>
    </Typography>
  )
}

export const CustomerFeedbackPanel: React.FC<CustomerFeedbackPanelProps> = ({
  close,
  isSubscribingProcessOn,
  entryPoint,
  isFromProfile = false
}) => {
  const [isConsentGranted, setIsConsentGranted] = useState(false)
  const {t} = useTranslation()
  const {data: roles} = useRoles()
  const {getFeature} = useFeaturesState()
  const {trackAnalytics} = useTrackEventFeedbackProgramme(roles)

  const {mutate, isLoading, isError, isSuccess} = useImprovementProgrammeMutation()
  const {classes} = usePanelStyles()
  const areCompaniesAffiliated = getFeature('AffiliatedCompanies')

  const closePanel = () => {
    return (
      <CustomHeader title={t('customerFeedbackPanel.header')}>
        <IconButton
          size="large"
          onClick={(e) => close(e)}
          data-test-id="customerFeedbackPanelCloseButton"
          className={classes.icon}
        >
          <CloseIcon />
        </IconButton>
      </CustomHeader>
    )
  }
  const handleSubscription = () => {
    isSubscribingProcessOn && isSubscribingProcessOn(true)
    mutate({state: CustomerFeedbackProgrammeAction.SUBSCRIBE})
    trackAnalytics(entryPoint, CustomerFeedbackProgrammeAction.SUBSCRIBE)
  }

  const user = useSelector<AppState, User | null>((state) => state.userProfile.userProfile)
  const linkToLegal = `${process.env.REACT_APP_LEGAL_URL}/policies/ac/${user?.country || ''}`

  const affiliatedCompanies = areCompaniesAffiliated ? (
    <a href={linkToLegal} target="_blank" rel="noreferrer">
      affiliated companies
    </a>
  ) : (
    <span>affiliated companies</span>
  )

  return (
    <>
      <div className={!isFromProfile ? classes.panelBox : ''} data-test-id="customerFeedbackPanel">
        {!isFromProfile && closePanel()}

        {isSuccess ? (
          <div className={classes.welcomeBox} data-test-id="customerFeedbackPanelWelcome">
            <img src={CustomerPanelSvg} alt="Customer panel" className={classes.welcomeImage} />
            <Typography variant="h1" className={classes.welcomeHeader}>
              {t('customerFeedbackPanel.welcomeHeader')}
            </Typography>
            <Typography className={classes.welcomeText}>
              {t('customerFeedbackPanel.welcomeText1')}
            </Typography>
            <Typography className={classes.welcomeText}>
              {t('customerFeedbackPanel.welcomeText2')}
            </Typography>
          </div>
        ) : (
          <>
            <FeedbackIntroductionSection />

            <div className={classes.consentBox}>
              <div className={classes.consentHeader}>
                {t('customerFeedbackPanel.consentHeader')}
              </div>
              <div className={classes.consentText}>
                <Typography>
                  <Trans t={t} i18nKey="customerFeedbackPanel.consentText">
                    I hereby consent to become part of the HMAG customer panel and thus will be
                    contacted by email or telephone by HMAG and its
                    {affiliatedCompanies}, on topics related to the use and further development of
                    digital products and on topics related to the conventional product range in the
                    field of cement, concrete, aggregates and asphalt. I am aware that I can revoke
                    the consent at any time for the future in the menu of the digital product I use.
                    I hereby consent to become part of the HMAG customer panel and thus will be
                    contacted by email or telephone by HMAG and its affiliated companies in Germany
                    on topics related to the use and further development of digital products and on
                    topics related to the conventional product range in the field of cement,
                    concrete, aggregates and asphalt.
                  </Trans>
                </Typography>
              </div>
            </div>
            <PanelRow
              text={t('customerFeedbackPanel.consentCheckbox')}
              icon={
                <Checkbox
                  checked={isConsentGranted}
                  onChange={(_, checked) => setIsConsentGranted(checked)}
                  className={classes.consentCheckbox}
                  data-test-id="customerFeedbackPanelConsentCheckbox"
                />
              }
            />
            <div className={classes.joinButtonBox}>
              <LoadingButton
                loading={isLoading}
                loadingPosition="start"
                disabled={!isConsentGranted || isLoading}
                startIcon={<MarkAsUnreadOutlinedIcon />}
                variant="contained"
                className={classes.joinButton}
                onClick={handleSubscription}
                data-test-id="customerFeedbackPanelJoinButton"
              >
                {t('customerFeedbackPanel.joinButton')}
              </LoadingButton>
            </div>
            {isError && <Typography color="error">{t('errorboundary.error')}</Typography>}
            <ReferPrivacyPolicy />
          </>
        )}
      </div>
    </>
  )
}
