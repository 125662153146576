import {useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'

import {api} from '../../../App.global'
import {LeadSubscriptions} from '../types'

export const useLeadSubscriptions = (customerId: string) => {
  return useQuery(
    ['subscriptions', {type: 'c60lead', customerId}],
    async () => {
      const params = {customerId}
      const res: AxiosResponse<LeadSubscriptions> = await api.get('/announcements/subscriptions', {
        params
      })
      return res.data
    },
    {
      enabled: Boolean(customerId)
    }
  )
}
