import {User} from '@hconnect/apiclient'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {useSelector} from 'react-redux'

import {AppState} from '../../../Root.store'
import {useScreenSizeLabel} from '../../Responsive.utils'
import {Role} from '../../../Roles/Roles.types'
import {getAvailableBusinessLines, getRolesList} from '../../../Roles/Roles.utils'

export const useTrackEventFeedbackProgramme = (roles: Role[] | undefined) => {
  const screenSizeLabel = useScreenSizeLabel()
  const user = useSelector<AppState, User | null>((state) => state.userProfile.userProfile)

  const trackAnalytics = (entryPoint, action) => {
    if (!user) return

    const isInternalUser = user?.isInternal || false
    const rolesList = getRolesList(roles ?? [])
    const businessLines = roles ? getAvailableBusinessLines(roles) : []

    trackEvent('hubCustomerPanelSubscription', {
      product: 'hub',
      entryPoint,
      breakpoint: screenSizeLabel,
      timestamp: new Date(),
      userLanguage: user?.defaultLocale?.toLowerCase()?.split('-')?.[0],
      country: user?.country || '',
      role: user?.hasRoles || [],
      isInternalUser,
      roles: rolesList,
      businessLines,
      action
    })
  }
  return {trackAnalytics}
}
