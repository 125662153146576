import {Theme} from '@mui/material'
import {makeStyles} from 'tss-react/mui'

const primaryColor = '#016ad4'
const primaryDarkColor = '#0050a1'
const textColor = '#00274d'

export const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    maxWidth: 416,
    borderColor: '#afe0fc',
    borderWidth: 2,
    borderStyle: 'solid',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    backgroundColor: '#eaf7ff',
    color: textColor,
    lineHeight: '22px',
    position: 'fixed',
    right: theme.spacing(4),
    marginLeft: theme.spacing(4),

    [theme.breakpoints.down('md')]: {
      bottom: '-5px !important'
    }
  },
  icon: {
    position: 'absolute',
    right: 0,
    border: 'none',
    boxShadow: 'none',
    color: `${theme.palette.secondary.dark}`,
    '&:hover': {
      border: 'none',
      boxShadow: 'none',
      background: 'transparent'
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1.5rem'
    }
  },
  header: {
    padding: theme.spacing(2, 3)
  },
  title: {
    fontWeight: 700,
    fontSize: 16
  },
  divider: {
    height: 6,
    background: 'linear-gradient(180deg, #D9D9D9 0%, rgba(217, 217, 217, 0) 100%)'
  },
  sentimentRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2)
  },
  sentimentIcon: {
    border: 'none',
    boxShadow: 'none',
    color: '#54708c',
    background: '#ffffff',
    '&:hover': {
      border: 'none',
      boxShadow: 'none',
      background: '#BCE4FF',
      color: '#2A4C6D'
    },
    '& .MuiSvgIcon-root': {
      fontSize: '2rem'
    },
    '&.selected': {
      background: '#78c9ff',
      color: '#00274d'
    }
  },
  comment: {
    margin: theme.spacing(2, 0)
  },
  answerTextInput: {
    width: '100%',
    '& .MuiFilledInput-root': {
      boxShadow: '0px 4px 4px 0px #1F495E14 inset !important',
      background: '#f1f9fe !important'
    },
    '& .MuiFormHelperText-root': {
      right: 0
    }
  },
  button: {
    width: '100%',
    height: 48,
    color: '#ffffff',
    backgroundColor: primaryColor,
    margin: theme.spacing(2, 0),
    '&:hover': {
      backgroundColor: primaryDarkColor,
      boxShadow: 'none'
    },
    '&:disabled': {
      color: '#ffffff',
      backgroundColor: '#b3d2f2 ',
      '& .MuiSvgIcon-root': {
        color: '#ffffff'
      }
    },
    '& .MuiSvgIcon-root': {
      color: '#ffffff'
    },
    '& .MuiLoadingButton-startIconLoadingStart': {
      opacity: 1
    }
  },
  thankYou: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(2, 3),
    color: '#00274D',
    h3: {
      fontSize: 20,
      fontWeight: 600
    },
    p: {
      textAlign: 'center',
      fontSize: 14
    }
  }
}))
