import {Theme} from '@mui/material'
import {makeStyles} from 'tss-react/mui'

export const useProfileStyles = makeStyles()((theme: Theme) => ({
  profileItem: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 'initial'
  },
  subscribedLabel: {
    padding: '4px 8px',
    marginLeft: '8px',
    fontSize: '12px',
    backgroundColor: '#E7F8EC',
    color: '#41A65D'
  },
  rightArrow: {
    marginLeft: 'auto',
    [theme.breakpoints.down('md')]: {
      marginRight: '4px'
    }
  },
  profileContainer: {
    overflow: 'auto',
    top: '74px !important',
    width: '100%',
    marginRight: '5%',
    padding: '16px',
    maxHeight: '549px',
    [theme.breakpoints.up('md')]: {
      marginRight: 0,
      width: 360,
      top: '70px !important',
      maxHeight: '700px'
    },
    '&::-webkit-scrollbar': {
      width: 6,
      borderRadius: '0 4px 8px 0'
    },
    '&::-webkit-scrollbar-track': {
      display: 'none'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#e2e2e2',
      borderRadius: '0 4px 4px 0'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#afafaf'
    }
  },
  backContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '8px',
    borderBottom: '1px solid #EAEDF0'
  },
  backTitle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  unsubscribeButtonBox: {marginTop: '24px'},
  unsubscribeButton: {
    fontSize: '14px',
    fontWeight: 500,
    display: 'flex',
    height: '48px',
    width: '100%',
    padding: '13px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#00274Dff',
    borderRadius: '6px',
    border: '1px solid rgba(0, 39, 77, 0.10)',
    background: '#FFF',
    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: '#f5f5f5',
      border: '1px solid rgba(0, 39, 77, 0.10)',
      color: '#00274Dff'
    },
    '&:disabled': {
      background: '#8080802e',
      '& .MuiSvgIcon-root': {
        color: '#00274d30'
      }
    },
    '& .MuiLoadingButton-startIconLoadingStart': {
      opacity: 1
    }
  }
}))
