import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import {Button, Collapse, TextField} from '@mui/material'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {SentimentButton, SentimentIcons} from './SentimentButton'
import {useStyles} from './style'
import {CustomerFeedback, CustomerQuestion} from './types'

const maxCommentLength = 200

export const Question: React.FC<{
  question: CustomerQuestion
  isLast: boolean
  submit: (feedback: CustomerFeedback) => void
}> = ({question, isLast, submit}) => {
  const {classes} = useStyles()
  const [sentiment, setSentiment] = useState<number>()
  const [comment, setComment] = useState<string>()
  const {t} = useTranslation()

  const isCommentValid =
    comment === undefined || (comment.length > 0 && comment.length <= maxCommentLength)
  const canSubmit = sentiment && (sentiment > 3 || (isCommentValid && comment))

  return (
    <div className={classes.header}>
      <div>{question.statement}</div>
      <div className={classes.sentimentRow}>
        {SentimentIcons.map((si, idx) => (
          <SentimentButton
            question={question.id}
            sentiment={idx + 1}
            key={si.key}
            setSentiment={setSentiment}
            isSelected={sentiment === idx + 1}
          />
        ))}
      </div>
      <Collapse in={!!sentiment && sentiment < 4}>
        <div className={classes.comment}>{question.comment}</div>
        <TextField
          label={t('customerFeedbackSurvey.yourComment')}
          multiline
          rows={6}
          variant="filled"
          helperText={`${comment?.length || 0}/${maxCommentLength}`}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          className={classes.answerTextInput}
          error={!isCommentValid}
          data-test-id={`question${question.id}Comment`}
        />
      </Collapse>
      <Collapse in={!!sentiment}>
        <Button
          className={classes.button}
          startIcon={<ArrowForwardIcon />}
          onClick={() => submit({id: question.id, comment, sentiment, question: question.statement})}
          disabled={!canSubmit}
          data-test-id={`question${question.id}SubmitButton`}
        >
          {isLast ? t('customerFeedbackSurvey.submit') : t('customerFeedbackSurvey.nextQuestion')}
        </Button>
      </Collapse>
    </div>
  )
}
