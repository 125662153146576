import React from 'react'
import {useTranslation} from 'react-i18next'

import DeliverDumpTruckSvg from '../../Assets/modeOfTransport/deliver-dump-truck.svg'
import DeliverMixerTruckSvg from '../../Assets/modeOfTransport/deliver-mixer-truck.svg'
import ShipSvg from '../../Assets/modeOfTransport/deliver-ship.svg'
import TrainSvg from '../../Assets/modeOfTransport/deliver-train.svg'
import DeliverTruckSvg from '../../Assets/modeOfTransport/deliver-truck-bulk-carrier.svg'
import PickupDumpTruckSvg from '../../Assets/modeOfTransport/pickup-dump-truck.svg'
import PickupMixerTruckSvg from '../../Assets/modeOfTransport/pickup-mixer-truck.svg'
import PickupTruckSvg from '../../Assets/modeOfTransport/pickup-truck-bulk-carrier.svg'
import {BusinessLineType, BusinessLineTypes} from '../../common/types'
import {Shippings} from '../../Pages/Finance/Invoices/Delivery.types'
import {ModeOfTransport, ModeOfTransportType, ShippingType} from '../../Pages/Order/Order.types'
import StatusTooltip from '../StatusTooltip'

import {ResolveModeOfTransport, resolveModeOfTransport} from './resolveModeOfTransport'

interface ModeOfTransportIconType {
  modeOfTransport?: ModeOfTransportType
  shippingType?: Shippings
  businessLine?: BusinessLineTypes
  uom: string
}

/* eslint-disable-next-line complexity */
export const ModeOfTransportIcon: React.FC<ModeOfTransportIconType> = ({
  modeOfTransport = ModeOfTransport.Truck,
  shippingType = ShippingType.Deliver,
  businessLine = BusinessLineType.CEM,
  uom
}) => {
  const {t} = useTranslation()
  const resolution = resolveModeOfTransport(modeOfTransport, shippingType, businessLine, uom)

  switch (resolution) {
    case ResolveModeOfTransport.DeliverMixerTruck:
      return (
        <StatusTooltip
          title={t(`order.modeOfTransport.${ResolveModeOfTransport.DeliverMixerTruck}`) || ''}
          placement="right"
        >
          <img src={DeliverMixerTruckSvg} height="24px" width="24px" alt="" role="img" />
        </StatusTooltip>
      )

    case ResolveModeOfTransport.DeliverBulkCarrier:
      return (
        <StatusTooltip
          title={t(`order.modeOfTransport.${ResolveModeOfTransport.DeliverBulkCarrier}`) || ''}
          placement="right"
        >
          <img src={DeliverTruckSvg} height="24px" width="24px" alt="" role="img" />
        </StatusTooltip>
      )

    case ResolveModeOfTransport.DeliverTruck:
      return (
        <StatusTooltip
          title={t(`order.modeOfTransport.${ResolveModeOfTransport.DeliverTruck}`) || ''}
          placement="right"
        >
          <img src={DeliverTruckSvg} height="24px" width="24px" alt="" role="img" />
        </StatusTooltip>
      )

    case ResolveModeOfTransport.DeliverDumpTruck:
      return (
        <StatusTooltip
          title={t(`order.modeOfTransport.${ResolveModeOfTransport.DeliverDumpTruck}`) || ''}
          placement="right"
        >
          <img src={DeliverDumpTruckSvg} height="24px" width="24px" alt="" role="img" />
        </StatusTooltip>
      )

    case ResolveModeOfTransport.DeliverTrain:
      return (
        <StatusTooltip
          title={t(`order.modeOfTransport.${ResolveModeOfTransport.DeliverTrain}`) || ''}
          placement="right"
        >
          <img src={TrainSvg} height="24px" width="24px" alt="" role="img" />
        </StatusTooltip>
      )

    case ResolveModeOfTransport.DeliverShip:
      return (
        <StatusTooltip
          title={t(`order.modeOfTransport.${ResolveModeOfTransport.DeliverShip}`) || ''}
          placement="right"
        >
          <img src={ShipSvg} height="24px" width="24px" alt="" role="img" />
        </StatusTooltip>
      )

    case ResolveModeOfTransport.PickupMixerTruck:
      return (
        <StatusTooltip
          title={t(`order.modeOfTransport.${ResolveModeOfTransport.PickupMixerTruck}`) || ''}
          placement="right"
        >
          <img src={PickupMixerTruckSvg} height="24px" width="24px" alt="" role="img" />
        </StatusTooltip>
      )

    case ResolveModeOfTransport.PickupDumpTruck:
      return (
        <StatusTooltip
          title={t(`order.modeOfTransport.${ResolveModeOfTransport.PickupDumpTruck}`) || ''}
          placement="right"
        >
          <img src={PickupDumpTruckSvg} height="24px" width="24px" alt="" role="img" />
        </StatusTooltip>
      )

    case ResolveModeOfTransport.PickupBulkCarrier:
      return (
        <StatusTooltip
          title={t(`order.modeOfTransport.${ResolveModeOfTransport.PickupBulkCarrier}`) || ''}
          placement="right"
        >
          <img src={PickupTruckSvg} height="24px" width="24px" alt="" role="img" />
        </StatusTooltip>
      )

    case ResolveModeOfTransport.PickupTruck:
      return (
        <StatusTooltip
          title={t(`order.modeOfTransport.${ResolveModeOfTransport.PickupTruck}`) || ''}
          placement="right"
        >
          <img src={PickupTruckSvg} height="24px" width="24px" alt="" role="img" />
        </StatusTooltip>
      )

    default:
      return (
        <StatusTooltip title="" placement="right">
          <img src={DeliverTruckSvg} height="24px" width="24px" alt="" role="img" />
        </StatusTooltip>
      )
  }
}
