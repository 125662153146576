import {User} from '@hconnect/apiclient'
import moment from 'moment'
import {useSelector} from 'react-redux'

import {useFeaturesState} from '../../../Organisms/Features'
import {AppState} from '../../../Root.store'

export interface ImprovementProgrammeDataType {
  userId: string
  lastTimePromptHiddenOn: Date
  promptIntervalWeeks: number
  subscribedOn: Date
  unsubscribedOn: Date
  product: string
  isSubscribed: boolean
}
import {useImprovementProgramme} from './useImprovementProgramme'

export const useBannerVisibility = () => {
  const {
    data: improvementProgrammeData,
    error: improvementProgrammeError,
    isLoading
  } = useImprovementProgramme()

  if (
    !useFeedbackProgrammeVisibility() ||
    isLoading ||
    improvementProgrammeError ||
    !improvementProgrammeData
  )
    return false

  const {isSubscribed, lastTimePromptHiddenOn, promptIntervalWeeks}: ImprovementProgrammeDataType =
    improvementProgrammeData
  if (isSubscribed) return false
  if (!lastTimePromptHiddenOn) return true

  const dateToday = new Date().toISOString()
  const addWeeks = moment(lastTimePromptHiddenOn).add(promptIntervalWeeks, 'weeks').toISOString()

  return addWeeks <= dateToday
}

export const useFeedbackProgrammeVisibility = () => {
  const {getFeature} = useFeaturesState()
  const isFeedbackProgrammeLeadWidget = getFeature('FeedbackProgrammeLeadWidget')
  const user = useSelector<AppState, User | null>((state) => state.userProfile.userProfile)
  if (!isFeedbackProgrammeLeadWidget || user?.isInternal || user?.isTester) return false

  return true
}
