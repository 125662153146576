/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {withRoute} from '@hconnect/uikit'
import React, {FC, ReactNode} from 'react'

// eslint-disable-next-line import/no-cycle
import {usePermissions} from '../Permissions'

import {PermissionDeniedError} from './PermissionDeniedError'

interface Props {
  requirePermission: string | string[]
  renderFallback: () => ReactNode
  children: ReactNode
}

export const PermissionBoundary: FC<Props> = ({children, requirePermission, renderFallback}) => {
  const {hasPermission, isFetchingPermissions} = usePermissions()

  const permissionAllowed =
    !requirePermission ||
    (Array.isArray(requirePermission)
      ? requirePermission.some((p) => hasPermission(p))
      : hasPermission(requirePermission))

  if (isFetchingPermissions) {
    return null
  }

  return <>{permissionAllowed ? children : renderFallback()}</>
}

export const withRouteRequiringPermission = (routeOptions) => (WrappedComponent) => {
  const {permissions} = routeOptions

  const PermissionBasedRouteComponent = (props) => (
    <PermissionBoundary
      requirePermission={permissions}
      renderFallback={() => <PermissionDeniedError />}
    >
      <WrappedComponent {...props} />
    </PermissionBoundary>
  )
  return withRoute(routeOptions)(PermissionBasedRouteComponent)
}

// eslint-disable-next-line import/no-default-export
export default PermissionBoundary
