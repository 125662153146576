/* eslint-disable complexity */
import {Tab, Tabs} from '@hconnect/uikit'
import {Box, Grid} from '@material-ui/core'
import React from 'react'
import {Link} from 'react-router-dom'

interface SubPage {
  key: string
  label: string
  path: string
  component: React.FC
}

interface SubPageNavigation {
  subPages: SubPage[]
  baseRoute: string
  tabValue: string
  setTabValue: (value: string) => void
  clearView?: () => void
  addOnComponent?: React.ReactNode
}

export const SubPageNavigation: React.FC<SubPageNavigation> = ({
  subPages,
  baseRoute,
  tabValue,
  setTabValue,
  clearView,
  addOnComponent
}) => {
  return (
    <Grid container>
      <Grid item md={6}>
        <Box paddingBottom={1} mb={5}>
          {subPages.length > 1 ? (
            <Tabs
              indicatorColor="primary"
              textColor="secondary"
              color="secondary"
              value={tabValue}
              onChange={(e, v) => {
                setTabValue(v)
                clearView && clearView()
              }}
            >
              {subPages.map((page) => (
                <Tab
                  data-test-id={`subpage-${page.key}`}
                  key={page.path.replace(/\//gm, '')}
                  label={page.label}
                  value={`${page.path}`}
                  component={Link}
                  to={`${baseRoute}${page.path}`}
                />
              ))}
            </Tabs>
          ) : null}
        </Box>
      </Grid>
      <Grid item md={1} />
      <Grid item md={5}>
        <Box paddingBottom={1} minHeight={140} mt={-5}>
          {addOnComponent ? addOnComponent : null}
        </Box>
      </Grid>
    </Grid>
  )
}
