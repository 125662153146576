import {Typography} from '@hconnect/uikit'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import {Box, Button, Card, CardContent, CardHeader} from '@mui/material'
import {TFunction} from 'i18next'
import React from 'react'

import C60Logo from '../../../Assets/c60_logo_white.svg'
import BgImage from '../../../Assets/c60card_bg.png'

// eslint-disable-next-line import/no-cycle
import {ContextualC60Lead} from './ContextualC60Lead'

const OFFSET = '-15px'

interface C60LeadBannerType {
  isSubscribed: boolean
  canSubscribe?: boolean
  expired?: boolean
  onHover: () => void
  onButtonClick: () => void
  t: TFunction | ((key: string) => string)
  contextualC60Widget?: boolean
  totalGrossValue?: number
}
export const C60LeadBanner: React.FC<C60LeadBannerType> = ({
  canSubscribe,
  isSubscribed,
  expired,
  onHover,
  onButtonClick,
  t,
  contextualC60Widget,
  totalGrossValue
}) => {
  // TODO: clarify if expired is not showing
  if (!canSubscribe && !(isSubscribed || expired)) {
    return null
  }

  return contextualC60Widget && totalGrossValue && totalGrossValue > 0 ? (
    <ContextualC60Lead
      onHover={onHover}
      onButtonClick={onButtonClick}
      totalGrossValue={totalGrossValue || 0}
    />
  ) : (
    <Card
      data-test-id="c60-lead-banner"
      onMouseEnter={() => onHover()}
      sx={{
        overflow: 'visible',
        backgroundImage: `url(${BgImage})`,
        borderRadius: 1,
        width: '372px',
        height: '278px',
        position: 'relative',
        maxWidth: '100%'
      }}
    >
      <CardHeader
        sx={{
          position: 'absolute',
          marginTop: OFFSET,
          right: '24px',
          width: '143px',
          height: '30px',
          bgcolor: '#38CA04',
          borderRadius: 0.5
        }}
        title={t('lead.c60.promo')}
        titleTypographyProps={{
          color: '#FFFFFF',
          variant: 'body2',
          textAlign: 'center',
          style: {fontSize: 12, fontWeight: 600}
        }}
      />
      <CardContent>
        <Box data-test-id="c60-lead-banner-content" display="flex" flexDirection="column" p={2}>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Box data-test-id="c60-lead-banner-logo" pt={0.75}>
              <img src={C60Logo} width="78px" alt="" />
            </Box>
            <Box data-test-id="c60-lead-banner-promo-title" ml={2}>
              <Typography variant="h3" color="textSecondary" align="left">
                {t('lead.c60.promoTitle')}
              </Typography>
            </Box>
          </Box>

          <Box data-test-id="c60-lead-banner-promo-followUp" pt={1}>
            <Typography variant="body1" color="textSecondary">
              {t('lead.c60.promoFollowUp')}
            </Typography>
          </Box>
          <Box data-test-id="c60-lead-banner-action-and-feedback" pt={2.5}>
            <Button
              data-test-id="c60-lead-banner-button"
              onClick={(event) => {
                event.preventDefault()
                onButtonClick()
              }}
              startIcon={<ArrowForwardIcon />}
              style={{
                borderRadius: 6,
                backgroundColor: '#FFFFFF',
                height: 48,
                color: '#016AD4',
                textTransform: 'none',
                border: '1px solid rgba(0, 39, 77, 0.1)',
                padding: '13px 40px 13px 40px',
                cursor: 'pointer'
              }}
            >
              <Typography variant="h4" style={{fontWeight: 600}}>
                {t('lead.c60.askForADemo')}
              </Typography>
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}
