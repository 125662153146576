import {Typography} from '@hconnect/uikit'
import {Box, Grid, Paper} from '@mui/material'
import React from 'react'

import {BoldText, SmallerHeader, TabCard, TinyDescription} from './components'
import type {ResponsiveImageType, TabCardType} from './types'

interface PicWithOverlayType {
  imageUrls: ResponsiveImageType
  overlayUrl: string
  overlayTitle: string
  overlayText: string
  smallScreen?: boolean
}
const PicWithOverlay: React.FC<PicWithOverlayType> = ({
  imageUrls,
  overlayText,
  overlayTitle,
  overlayUrl,
  smallScreen
}) => (
  <Box
    sx={{
      position: smallScreen ? 'unset' : 'relative',
      display: smallScreen ? 'flex' : 'inline',
      flexDirection: 'column',
      ...(smallScreen ? {} : {marginRight: 'auto'})
    }}
  >
    <img
      style={{overflow: 'auto'}}
      srcSet={`${imageUrls.sm} 728w, ${imageUrls.md} 1455w`}
      sizes="(max-width: 480px) 320px, (min-width: 901px) 768px"
      src={imageUrls.lg}
      alt=""
    />
    <Box
      sx={{
        position: smallScreen ? 'unset' : 'absolute',
        ...(smallScreen ? {} : {bottom: -60}),
        ...(smallScreen ? {} : {right: '-10%'})
      }}
    >
      <Paper elevation={4} sx={{zIndex: 10, minHeight: 186, maxWidth: 446}}>
        <Box display="flex" px={5} py={2.5} flexDirection="column" alignItems="flex-start">
          <img
            src={overlayUrl}
            height="38px"
            style={{objectFit: 'contain', marginBottom: 16}}
            alt=""
          />
          <BoldText variant="body1" gutterBottom>
            {overlayTitle}
          </BoldText>
          <Typography variant="body2">{overlayText}</Typography>
        </Box>
      </Paper>
    </Box>
  </Box>
)

interface Type {
  showcaseId: string
  tabs: TabCardType[]
  smallScreen?: boolean
  showcaseTitle?: string
  showcaseSubtitle?: string
  onTabClick: (tab: string) => void
}

export const VerticalTabsShowcase: React.FC<Type> = ({
  showcaseId,
  tabs,
  smallScreen = false,
  showcaseTitle,
  showcaseSubtitle,
  onTabClick
}) => {
  const [activeTab, setActiveTab] = React.useState<TabCardType>(tabs[0])
  const [activeImage, setActiveImage] = React.useState<ResponsiveImageType>(tabs[0].imageUrls)

  React.useEffect(() => {
    setActiveImage(activeTab.imageUrls)
  }, [activeTab])

  return (
    <Paper
      elevation={0}
      sx={{
        borderRadius: 1
      }}
    >
      <Grid
        data-test-id={`vertical-tabs-showcase-${showcaseId}`}
        container
        p={smallScreen ? 2.5 : 6.25}
        display="flex"
        flexDirection="row"
        rowSpacing={1}
        columnSpacing={12}
      >
        <Grid data-test-id={`tabs-showcase-${showcaseId}`} item xs={12} sm={12} md={5} container>
          {showcaseTitle && showcaseSubtitle ? (
            <Box
              data-test-id={`tabs-showcase-title-${showcaseId}`}
              display="flex"
              flexDirection="column"
              mb={1}
            >
              <SmallerHeader variant="h3" gutterBottom>
                {showcaseTitle}
              </SmallerHeader>
              <TinyDescription variant="caption" gutterBottom>
                {showcaseSubtitle}
              </TinyDescription>
            </Box>
          ) : null}
          {tabs.map((tab) => (
            <Box key={tab.tabId} width="100%">
              <TabCard
                tab={tab}
                title={tab.title}
                subtitle={tab.subtitle}
                active={tab.tabId === activeTab.tabId}
                setActive={setActiveTab}
                variant={tab.variant}
                variantText={tab.variantTitle}
                smallScreen={smallScreen}
                onTabClick={onTabClick}
              />
              {smallScreen && tab.tabId === activeTab.tabId ? (
                <Grid item xs={12} sm={12} md={6} mb="auto">
                  <Box display="flex" justifyContent="center">
                    {activeTab.overlayUrl && activeTab.overlayTitle && activeTab.overlayText ? (
                      <PicWithOverlay
                        imageUrls={activeImage}
                        overlayUrl={activeTab.overlayUrl}
                        overlayTitle={activeTab.overlayTitle}
                        overlayText={activeTab.overlayText}
                        smallScreen={smallScreen}
                      />
                    ) : (
                      <img
                        style={{overflow: 'auto'}}
                        srcSet={`${activeImage.sm} 728w, ${activeImage.md} 1455w`}
                        sizes="(max-width: 480px) 300px, (min-width: 901px) 760px"
                        src={activeImage.md}
                        alt=""
                      />
                    )}
                  </Box>
                </Grid>
              ) : null}
            </Box>
          ))}
        </Grid>
        {!smallScreen ? (
          <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
            <Box display="flex" justifyContent="flex-end">
              {activeTab.overlayUrl && activeTab.overlayTitle && activeTab.overlayText ? (
                <PicWithOverlay
                  imageUrls={activeImage}
                  overlayUrl={activeTab.overlayUrl}
                  overlayTitle={activeTab.overlayTitle}
                  overlayText={activeTab.overlayText}
                  smallScreen={smallScreen}
                />
              ) : (
                <img
                  srcSet={`${activeImage.sm} 728w, ${activeImage.md} 1455w`}
                  sizes="(max-width: 480px) 320px, (min-width: 901px) 780px"
                  src={activeImage.md}
                  alt=""
                />
              )}
            </Box>
          </Grid>
        ) : null}
      </Grid>
    </Paper>
  )
}
