import {Overlay} from '@hconnect/uikit/src/lib/Shell/Overlay'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import CloseIcon from '@mui/icons-material/Close'
import FilterIcon from '@mui/icons-material/FilterList'
import SearchIcon from '@mui/icons-material/Search'
import {Box, Button, Divider, IconButton, InputBase, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'

import {LookupSelectItems} from '../../Pages/Order/Order.enum'
import {removeLookupFilter} from '../../Pages/Order/Order.filters'

import {useSMFilterStyles} from './SMFilterStyles'

type FilterSMViewProps = {
  isVisible: boolean
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
  filters: JSX.Element[]
  numberOfItems?: number
  apply: () => void
  onSearch?: ({entity, value, label}: {entity: any; value: any; label: any}) => void
}
export const SMFilter: React.FC<FilterSMViewProps> = ({
  isVisible,
  setIsVisible,
  filters,
  numberOfItems,
  apply,
  onSearch
}) => {
  const {t} = useTranslation()
  const classes = useSMFilterStyles()
  const dispatch = useDispatch()
  const close = () => setIsVisible(false)

  const [searchValue, setSearchValue] = React.useState('')
  const [searchSelected, setSearchSelected] = React.useState<boolean>(false)
  if (!isVisible) {
    return (
      <>
        <Box
          sx={{zIndex: 10000}}
          data-test-id="sm-filter-closed"
          width="100%"
          display="flex"
          flexDirection="row"
          px={2}
          py={1}
        >
          <Box flex="1" height="fit-content" alignSelf="center">
            {searchSelected ? (
              <InputBase
                autoFocus
                placeholder={t('order.lookup.orderPlchld')}
                inputProps={{'aria-label': 'search with number'}}
                onChange={(event) => setSearchValue(event.target.value)}
              />
            ) : (
              <Typography variant="h3" color="textPrimary">
                {t('filterMenu.itemsListed', {numberOfItems})}
              </Typography>
            )}
          </Box>

          {onSearch ? (
            <IconButton
              data-test-id="enable-search-button"
              type="button"
              style={{border: 0, boxShadow: 'none'}}
              aria-label="search"
              disableRipple
              disableFocusRipple
              onClick={() => {
                searchSelected
                  ? onSearch &&
                    Boolean(searchValue.trim()) &&
                    onSearch({
                      entity: LookupSelectItems.ORDER,
                      label: '',
                      value: searchValue.trim()
                    })
                  : setSearchSelected(true)
              }}
            >
              <SearchIcon fontSize="large" />
            </IconButton>
          ) : null}
          {!searchSelected ? (
            <IconButton
              aria-label="filter"
              onClick={() => setIsVisible(true)}
              style={{border: 0, boxShadow: 'none'}}
              disableRipple
              disableFocusRipple
            >
              <FilterIcon fontSize="large" />
            </IconButton>
          ) : (
            <IconButton
              style={{border: 0, boxShadow: 'none'}}
              aria-label="filter"
              onClick={() => {
                setSearchSelected(false)
                dispatch(removeLookupFilter())
              }}
              disableRipple
              disableFocusRipple
            >
              <CloseIcon fontSize="large" />
            </IconButton>
          )}
        </Box>
        <Divider variant="middle" />
      </>
    )
  }

  return (
    <Overlay
      data-test-id="sm-filter-opened"
      isVisible={isVisible}
      overlayClassName={classes.overlay}
      contentClassName={classes.overlayContent}
    >
      <Box
        bgcolor="background.paper"
        color="text.primary"
        sx={{
          mt: 1,
          p: 4,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          position: 'sticky',
          justifyContent: 'space-between'
        }}
      >
        <Box>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h3">{t('filterMenu.filter')}</Typography>
            <IconButton className={classes.closeIcon} onClick={() => close()}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider />
          {filters.map((filter, index) => (
            <Box key={index} className={classes.filter} mt={4}>
              {filter}
            </Box>
          ))}
        </Box>
        <Box mb={10}>
          <Button
            className={classes.applyButton}
            variant="contained"
            startIcon={<ArrowForwardIcon />}
            onClick={() => {
              apply()
              close()
            }}
          >
            {t('filterMenu.applyFilter')}
          </Button>
        </Box>
      </Box>
    </Overlay>
  )
}
