import {Box, Skeleton} from '@mui/material'
import React from 'react'

export const CustomSkeleton: React.FC = () => (
  <Box display="flex" alignItems="center" minWidth="350px" py={1}>
    <Box display="flex" flexDirection="column" p={1} justifyContent="flex-start" width="100%">
      <Box display="flex" flexDirection="row" width="100%" justifyContent="space-between" py={1}>
        <Skeleton variant="circular" width={40} height={40} />
        <Box px={1} />
        <Skeleton variant="text" sx={{fontSize: '1rem'}} width="100%" />
      </Box>
      <Skeleton variant="rectangular" width="100%" height={60} />
      <Box py={1} />
      <Skeleton variant="rounded" width="100%" height={60} />
    </Box>
  </Box>
)
