import HproduceIconButton from '@hconnect/uikit/src/lib/Shell/HproduceIconButton'
import {Close as CloseIcon} from '@material-ui/icons'
import classNames from 'classnames'
import React from 'react'

import {useAccountMenuStyles} from './AccountMenuStyles'

type AccountButtonProps = {
  open: boolean
  userIcon: JSX.Element
  className: string
  onClick: () => void
  dataTestId?: string
}

export const AccountButton: React.FC<AccountButtonProps> = ({
  open = false,
  userIcon,
  className,
  onClick,
  dataTestId = 'account-button'
}) => {
  const classes = useAccountMenuStyles()

  return (
    <HproduceIconButton
      dataTestId={dataTestId}
      className={classNames({[classes.activeMenuButton]: open}, className)}
      onClick={onClick}
      size="medium"
      focusRipple={open}
    >
      {open ? <CloseIcon fontSize="small" /> : userIcon}
    </HproduceIconButton>
  )
}
