import {useState} from 'react'

import {useDistributedStorageClient} from '../../../../DistributedStorage'

export const DistributedCacheKey = 'CustomerFeedbackSurveyState'

export type CustomerFeedbackSurveyStatus = 'dismissed' | 'submitted' | 'unknown' | 'error'

export interface CustomerFeedbackSurveyState {
  status: CustomerFeedbackSurveyStatus
  date: Date
}

export const useCustomerFeedbackSurveyState = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [surveyState, setState] = useState<CustomerFeedbackSurveyState>()

  const client = useDistributedStorageClient()

  if (!surveyState) {
    client
      .getItem<CustomerFeedbackSurveyState>(DistributedCacheKey)
      .then((x) => setState(x || {status: 'unknown', date: new Date()}))
      .catch(() => setState({status: 'error', date: new Date()}))
      .finally(() => setIsLoading(false))
  }

  const setSurveyStatus = (newStatus: CustomerFeedbackSurveyStatus) => {
    const state = {status: newStatus, date: new Date()}
    client
      .setItem(DistributedCacheKey, state)
      .then(() => setState(state))
      .catch(() => setState({status: 'error', date: new Date()}))
  }

  return {surveyState, setSurveyStatus, isLoadingSurveyStatus: isLoading}
}
