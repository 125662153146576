import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'

import {api} from '../../../App.global'

export const useImprovementProgramme = () => {
  return useQuery(['improvementProgramme', {}], async () => {
    const res = await api.get('/improvementProgramme/status', {})
    return res.data
  })
}
export const useImprovementProgrammeMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({state}: {state: 'subscribe' | 'unsubscribe' | 'dismiss'}) => {
      return api.post(`/improvementProgramme/${state}`, {})
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({queryKey: ['improvementProgramme']})
      }
    }
  )
}
