import WarningSvg from '@material-ui/icons/Warning'
import React from 'react'

interface WarningIconType {
  fill: string
  height: number
  width: number
  size?: 'small' | 'inherit' | 'default' | 'large'
}

const WarningIcon: React.FC<WarningIconType> = ({fill, height, width, size}) => (
  <WarningSvg height={`${height}px`} width={`${width}px`} htmlColor={fill} fontSize={size} />
)

export default WarningIcon
