import {getLanguageTitle} from '@hconnect/common/utils/language.util'
import {flags} from '@hconnect/uikit/src/common/flags'
import {Box, ListItemText, MenuItem, Typography} from '@material-ui/core'
import {Translate as TranslateIcon} from '@material-ui/icons'
import CheckIcon from '@mui/icons-material/Check'
import {t} from 'i18next'
import React from 'react'

import {Locales} from './AccountMenu'
import {useAccountMenuStyles} from './AccountMenuStyles'
import {SubMenu} from './SubMenu'

type LanguageMenuProps = {
  languages: Locales
  selectLanguage: (language: string) => void
  openSubMenu: (menu: JSX.Element | null) => void
}

const getFlag = (languageCode: string): string | undefined => {
  return flags && flags[languageCode] ? (flags[languageCode] as string) : undefined
}

export const LanguageMenu: React.FC<LanguageMenuProps> = ({
  languages,
  selectLanguage,
  openSubMenu
}) => {
  const classes = useAccountMenuStyles()

  const decodeLanguage = (languageCode: string) => {
    return languageCode.split('-') || []
  }
  const [currentLanguage, currentCountry] = decodeLanguage(languages.defaultLocale)

  const subMenuItems = languages.locales.map((language, index) => {
    const [languageCode, countryCode] = decodeLanguage(language.code)
    return (
      <MenuItem
        key={index}
        className={classes.item}
        onClick={() => {
          selectLanguage(language.code)
          openSubMenu(null)
        }}
      >
        <img className={classes.flag} src={getFlag(language.code)} alt={language.name} />
        <ListItemText
          primary={getLanguageTitle(languageCode, countryCode) || t(`locales.${language.code}`)}
        />
        {languageCode === currentLanguage && <CheckIcon />}
      </MenuItem>
    )
  })

  const menuItem = (
    <Box sx={{display: 'flex'}}>
      <TranslateIcon className={classes.icon} />
      <Typography>
        {getLanguageTitle(currentLanguage, currentCountry) ||
          t(`locales.${languages.defaultLocale}`)}
      </Typography>
    </Box>
  )

  return (
    <SubMenu
      title={t('accountMenu.language')}
      icon={<TranslateIcon className={classes.icon} />}
      menuItem={menuItem}
      subMenuItems={subMenuItems}
      openSubMenu={openSubMenu}
      dividers
      dataTestId="language-menu"
    />
  )
}
