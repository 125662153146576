import {Typography} from '@mui/material'

interface IMaterialDescription {
  materialDescription?: string
  customerMaterialDescription?: string
}
const MaterialDescription = ({
  materialDescription,
  customerMaterialDescription
}: IMaterialDescription) => {
  return (
    <>
      {materialDescription}
      {customerMaterialDescription ? (
        <Typography variant="body1" color="secondary" paragraph style={{wordBreak: 'break-word'}}>
          {customerMaterialDescription}
        </Typography>
      ) : null}
    </>
  )
}

export default MaterialDescription
