import {Theme} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import React from 'react'

import {LoadMoreButton} from './LoadMoreButton'
import {LoadMoreIndicator} from './LoadMoreIndicator'

const useStyles = makeStyles((theme: Theme) => ({
  loadMore: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    margin: `${theme.spacing(6)}px 0 ${theme.spacing(6)}px 0`
  }
}))

interface LoadMoreProps {
  isFetching: boolean
  skip: number
  onLoadMoreClick(): void
  onLight?: boolean
}

export const LoadMore: React.FC<LoadMoreProps> = ({
  isFetching,
  skip,
  onLoadMoreClick,
  onLight = false
}) => {
  const classes = useStyles()
  return (
    <>
      {!isFetching && (
        <div data-test-id="pagination-container" className={classes.loadMore}>
          <LoadMoreButton isFetching={isFetching} onClick={onLoadMoreClick} onLight={onLight} />
        </div>
      )}
      {isFetching && skip !== 0 && (
        <div data-test-id="pagination-container" className={classes.loadMore}>
          <LoadMoreIndicator />
        </div>
      )}
    </>
  )
}
