import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied'
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral'
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied'
import {IconButton} from '@mui/material'
import classnames from 'classnames'

import {useStyles} from './style'

export const SentimentIcons = [
  <SentimentVeryDissatisfiedIcon key={1} />,
  <SentimentDissatisfiedIcon key={2} />,
  <SentimentNeutralIcon key={3} />,
  <SentimentSatisfiedIcon key={4} />,
  <SentimentVerySatisfiedIcon key={5} />
]

export const SentimentButton: React.FC<{
  sentiment: number
  question: number
  isSelected: boolean
  setSentiment: (sentiment: number) => void
}> = ({sentiment, question, isSelected, setSentiment}) => {
  const {classes} = useStyles()
  return (
    <IconButton
      size="large"
      onClick={() => setSentiment(sentiment)}
      data-test-id={`question${question}Sentiment${sentiment}`}
      className={classnames(classes.sentimentIcon, isSelected ? 'selected' : '')}
    >
      {SentimentIcons[sentiment - 1]}
    </IconButton>
  )
}
