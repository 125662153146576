export interface LeadSubscriptions {
  status: LeadSubscriptionsStatus
}

export enum LeadSubscriptionsEnum {
  default = 'default',
  cannotSubscribe = 'cannotSubscribe',
  canSubscribe = 'canSubscribe',
  subscribed = 'subscribed',
  expired = 'expired'
}

export type LeadSubscriptionsStatus = keyof typeof LeadSubscriptionsEnum
export interface LeadSubscriptionsBody {
  analyticsId: string
  customerId: string
  countryCode: string
}

export interface LeadAnalyticsType {
  userId: string | undefined
  userMail: string | null | undefined
  userCountry: string | null | undefined
  userRoles: string[] | undefined
  userIsInternal: boolean | undefined
  customerId: string | undefined
  customerName: string | undefined
  filteredDateRange?: {startDate: Date | string; endDate: Date | string}
  analyticsId?: string
  errorMessage?: string
}
