import {TitleNav} from '@hconnect/uikit'
import {Box} from '@material-ui/core'
import React from 'react'

import {useResponsiveBreakpoints} from '../../Molecules/Responsive.utils'

interface Type {
  onClick: () => void
  title: string
  ariaLabel?: string
  dataTestId?: string
  exportButton?: React.ReactNode
}
export const TitleNavigation: React.FC<Type> = ({
  onClick,
  title,
  ariaLabel,
  dataTestId,
  exportButton,
  ...otherProps
}) => {
  const {smallScreen} = useResponsiveBreakpoints()

  return (
    <Box
      px={smallScreen ? 1 : 0}
      py={1}
      height={54}
      display="flex"
      justifyContent="space-between"
      {...otherProps}
    >
      <TitleNav title={title} onClick={onClick} aria-label={ariaLabel} data-test-id={dataTestId} />
      {exportButton}
    </Box>
  )
}
