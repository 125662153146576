import {TextButton} from '@hconnect/uikit'
import {
  Button,
  Menu,
  MenuItem,
  Typography,
  ExtendButtonBase,
  ButtonTypeMap,
  Box,
  IconButton
} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import ReceiptIcon from '@material-ui/icons/Receipt'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import React, {useCallback, useRef, useState} from 'react'

interface ExportButtonProps {
  title: string
  innerRef?: any
  onClick?: () => void
  style?: any
  onLight?: boolean
  responsiveStyle?: 'desktop' | 'mobile'
}
export const ExportButton: React.FC<
  Partial<ExtendButtonBase<ButtonTypeMap<{}, 'button'>>> & ExportButtonProps
> = ({title, onLight, responsiveStyle, ...props}) => {
  return (
    <Box display="flex" alignItems="center">
      {responsiveStyle ? (
        responsiveStyle === 'desktop' ? (
          <TextButton
            color="secondary"
            variant="outlined"
            style={{color: '#fff', padding: '0 48px', textTransform: 'none', height: 38}}
            startIcon={<SaveAltIcon />}
            {...props}
          >
            {` ${title}`}
          </TextButton>
        ) : (
          <IconButton
            color="primary"
            style={{backgroundColor: '#fff', border: 'none', height: 38, width: 38}}
            {...props}
          >
            <SaveAltIcon />
          </IconButton>
        )
      ) : onLight ? (
        <Button color="secondary" {...props}>
          <ReceiptIcon style={{marginRight: 15}} />
          {` ${title}`}
        </Button>
      ) : (
        <TextButton color="secondary" variant="text" startIcon={<ReceiptIcon />} {...props}>
          {` ${title}`}
        </TextButton>
      )}
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  listItem: {
    minHeight: theme.spacing(6)
  },
  item: {
    fontSize: '0.86rem',
    fontWeight: 700
  }
}))

interface ExportOption {
  name: string
  label: string
  onClick: () => void
  href?: string
  divider?: boolean
}

interface ExportDropdownProps {
  title?: string
  href?: string
  options: ExportOption[]
  style?: any
  disabled?: boolean
  createJobId: () => void
  onClose: () => void
  onLight?: boolean
  responsiveStyle?: 'desktop' | 'mobile'
}
const ExportDropdown: React.FC<ExportDropdownProps> = ({
  options = [],
  title = '',
  onClose,
  createJobId,
  onLight = false,
  responsiveStyle,
  ...otherProps
}) => {
  const classes = useStyles()
  const [isOpen, setOpen] = useState(false)
  const anchorEl = useRef()
  const handleToggleOpen = useCallback(() => {
    setOpen(!isOpen)
    createJobId()
  }, [setOpen, isOpen])
  const handleClose = useCallback(() => {
    setOpen(false)
    onClose()
  }, [setOpen])

  return (
    <Box>
      <ExportButton
        innerRef={anchorEl}
        aria-label={title}
        aria-owns={isOpen ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggleOpen}
        title={title}
        data-test-id="export-button"
        onLight={onLight}
        responsiveStyle={responsiveStyle}
        {...otherProps}
      />

      <Menu
        open={isOpen}
        onClose={handleClose}
        anchorEl={anchorEl.current}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        getContentAnchorEl={null}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {options.map((option) => (
          <MenuItem
            data-test-id={option.name}
            key={option.label}
            aria-label={option.label}
            className={classes.listItem}
            onClick={() => {
              if (option.onClick) {
                option.onClick()
              } else {
                window.location.href = option?.href || ''
              }
              handleClose()
            }}
            divider={option.divider}
          >
            <Typography
              variant="body2"
              classes={{
                body2: classes.item
              }}
              color="inherit"
            >
              {option.label}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}

export default ExportDropdown
