import React from 'react'

import ArrivedDeliverySvg from '../../Assets/deliverystatusicon/arrived.svg'
import CancelledDeliverySvg from '../../Assets/deliverystatusicon/cancelled.svg'
import CompletedDeliverySvg from '../../Assets/deliverystatusicon/completed.svg'
import InTransitDeliverySvg from '../../Assets/deliverystatusicon/intransit.svg'
import LoadedDeliverySvg from '../../Assets/deliverystatusicon/loaded.svg'
import LoadingDeliverySvg from '../../Assets/deliverystatusicon/loading.svg'
import OpenDeliverySvg from '../../Assets/deliverystatusicon/open.svg'
import ReturnedDeliverySvg from '../../Assets/deliverystatusicon/returned.svg'
import WaitingForLoadDeliverySvg from '../../Assets/deliverystatusicon/waiting.svg'
import ActiveSvg from '../../Assets/statusIcon/active.svg'
import CancelledSvg from '../../Assets/statusIcon/cancelled.svg'
import ClosedSvg from '../../Assets/statusIcon/closed.svg'
import CompletedSvg from '../../Assets/statusIcon/completed.svg'
import ConfirmedSvg from '../../Assets/statusIcon/confirmed.svg'
import PaymentPendingSvg from '../../Assets/statusIcon/pendingPayment.svg'
import RejectedSvg from '../../Assets/statusIcon/rejected.svg'
import UnconfirmedSvg from '../../Assets/statusIcon/unconfirmed.svg'
import {DeliveryStatus} from '../../Pages/Finance/Invoices/Delivery.types'
import {OrderStatus, ShippingType} from '../../Pages/Order/Order.types'

export enum StatusIconVariant {
  Delivery = 'delivery',
  Order = 'order'
}

interface StatusIconType {
  status: OrderStatus | DeliveryStatus
  variant?: StatusIconVariant
  shippingType?: ShippingType
  small?: boolean
}

/* eslint-disable-next-line complexity */
const StatusIcon: React.FC<StatusIconType> = ({
  status,
  variant = StatusIconVariant.Order,
  shippingType = ShippingType.Collect,
  small = false
}) => {
  const height = small ? '16px' : '24px'
  if (variant === StatusIconVariant.Order) {
    switch (status) {
      case OrderStatus.Reserved:
      // fall through

      case OrderStatus.Pending:
        return <img src={UnconfirmedSvg} height={height} alt="" />

      case OrderStatus.Confirmed:
        return <img src={ConfirmedSvg} height={height} alt="" />

      case OrderStatus.Cancelled:
        return <img src={CancelledSvg} height={height} alt="" />

      case OrderStatus.InProgress:
        return <img src={ActiveSvg} height={height} alt="" />

      case OrderStatus.PartiallyDelivered:
        return <img src={ActiveSvg} height={height} alt="" />

      case OrderStatus.PartiallyShipped:
        return <img src={ActiveSvg} height={height} alt="" />

      case OrderStatus.Shipped:
        if (shippingType === ShippingType.Collect) {
          return <img src={CompletedSvg} height={height} alt="" />
        }
        return <img src={ActiveSvg} height={height} alt="" />

      case OrderStatus.Delivered:
        return <img src={CompletedSvg} height={height} alt="" />

      case OrderStatus.Rejected:
        return <img src={RejectedSvg} height={height} alt="" />

      case OrderStatus.Closed:
        return <img src={ClosedSvg} height={height} alt="" />

      case OrderStatus.PaymentPending:
        return <img src={PaymentPendingSvg} height={height} alt="" />

      default:
        return <img src={UnconfirmedSvg} height={height} alt="" />
    }
  } else if (variant === StatusIconVariant.Delivery) {
    switch (status) {
      case DeliveryStatus.Open:
        return <img src={OpenDeliverySvg} height={height} alt="" />

      case DeliveryStatus.WaitingForLoad:
        return <img src={WaitingForLoadDeliverySvg} height={height} alt="" />

      case DeliveryStatus.Loading:
        return <img src={LoadingDeliverySvg} height={height} alt="" />

      case DeliveryStatus.Loaded:
        return <img src={LoadedDeliverySvg} height={height} alt="" />

      case DeliveryStatus.InTransit:
        return <img src={InTransitDeliverySvg} height={height} alt="" />

      case DeliveryStatus.WaitingForUnload:
      // fall through

      case DeliveryStatus.Unloading:
        return <img src={ArrivedDeliverySvg} height={height} alt="" />

      case DeliveryStatus.Completed:
      // fall through

      case DeliveryStatus.Unloaded:
        return <img src={CompletedDeliverySvg} height={height} alt="" />

      case DeliveryStatus.Cancelled:
        return <img src={CancelledDeliverySvg} height={height} alt="" />

      case DeliveryStatus.Returned:
        return <img src={ReturnedDeliverySvg} height={height} alt="" />

      default:
        return <img src={OpenDeliverySvg} height={height} alt="" />
    }
  }

  return <img src={UnconfirmedSvg} height={height} alt="" />
}

export default StatusIcon
