import MarkAsUnreadOutlinedIcon from '@mui/icons-material/MarkAsUnreadOutlined'
import {LoadingButton} from '@mui/lab'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {FeedbackIntroductionSection, ReferPrivacyPolicy} from '../CustomerFeedbackPanel'
import {useImprovementProgrammeMutation} from '../hooks'

import {useProfileStyles} from './styles'
import {useTrackEventFeedbackProgramme} from '../hooks/useTrackEventFeedbackProgramme'
import {CustomerFeedbackProgrammeAction} from '../../../common/constants'
import {useRoles} from '../../../Roles'
interface Type {
  close: (event: Event | React.SyntheticEvent) => void
}

export const SubscribedPopover: React.FC<Type> = ({close}) => {
  const {t} = useTranslation()
  const {data: roles} = useRoles()
  const {classes} = useProfileStyles()
  const {mutateAsync, isLoading} = useImprovementProgrammeMutation()
  const {trackAnalytics} = useTrackEventFeedbackProgramme(roles)

  const handleClick = async (event: Event | React.SyntheticEvent) => {
    event.stopPropagation()
    await mutateAsync({state: CustomerFeedbackProgrammeAction.UNSUBSCRIBE})
    trackAnalytics('userProfile', CustomerFeedbackProgrammeAction.UNSUBSCRIBE)
    !isLoading && close(event)
  }

  return (
    <div data-test-id="feedbackPanelUnsubscibePopover">
      <FeedbackIntroductionSection />
      <ReferPrivacyPolicy />

      <div className={classes.unsubscribeButtonBox}>
        <LoadingButton
          loading={isLoading}
          loadingPosition="start"
          startIcon={<MarkAsUnreadOutlinedIcon />}
          variant="outlined"
          className={classes.unsubscribeButton}
          onClick={(e) => void handleClick(e)}
          data-test-id="customerFeedbackPanelUnsubscribeButton"
        >
          {t('customerFeedbackPanel.unsubscribeButton')}
        </LoadingButton>
      </div>
    </div>
  )
}
