import {Typography} from '@hconnect/uikit'
import {Box} from '@material-ui/core'
import React from 'react'
import {useTranslation} from 'react-i18next'

interface ResultsForMessageType {
  messageList: (string | null | undefined)[]
}

export const ResultsForMessage: React.FC<ResultsForMessageType> = ({messageList}) => {
  const {t} = useTranslation()
  const message = messageList.filter(Boolean).join(', ')
  return (
    <Box px={4} py={2}>
      <Typography variant="h3">{t('resultsFor', {message})}</Typography>
    </Box>
  )
}
