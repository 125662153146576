import {Box} from '@material-ui/core'
import {InfoOutlined} from '@material-ui/icons'
import React from 'react'

import StatusTooltip from './StatusTooltip'

interface TooltipColumnLabelType {
  tLabel: string
  tTooltip: string
}
export const TooltipColumnLabel: React.FC<TooltipColumnLabelType> = ({tLabel, tTooltip}) => (
  <Box display="flex" alignItems="center">
    {tLabel}
    <Box mr={0.5} />
    <StatusTooltip title={tTooltip} placement="right">
      <InfoOutlined />
    </StatusTooltip>
  </Box>
)
