import {Box, Button} from '@mui/material'
import {TFunction} from 'i18next'
import {styles} from './DownloadAllDeliveryNotes/DownloadAllDeliveryNotes'

type IViewAllDeliveries = {
  t: TFunction
  totalDeliveries?: number
  expand: () => void
}

export const ViewAllDeliveries = ({t, totalDeliveries, expand}: IViewAllDeliveries) => {
  const {classes} = styles()

  if (!totalDeliveries || totalDeliveries < 1) return null
  return (
    <Box className={classes.buttonContainer}>
      <Button
        onClick={() => expand()}
        data-test-id="view-all-deliveries"
        variant="contained"
        sx={{height: 48, width: '100%'}}
      >
        {t('order.viewAllDeliveries', {
          totalDeliveries: totalDeliveries
        })}
      </Button>
    </Box>
  )
}
