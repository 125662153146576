import {useState} from 'react'

import {useDistributedStorageClient} from '../../DistributedStorage'

export const DistributedCacheKey = 'DataTableCheckboxInformationBanner'

export type CustomerFeedbackSurveyStatus = 'dismissed' | 'unknown' | 'error'

export interface CustomerFeedbackSurveyState {
  status: CustomerFeedbackSurveyStatus
  date: Date
}

export const useInformationBannerState = (pageName: string) => {
  const [isLoading, setIsLoading] = useState(true)
  const [surveyState, setState] = useState<CustomerFeedbackSurveyState>()

  const client = useDistributedStorageClient()

  const key = `${DistributedCacheKey}-${pageName}`

  if (!surveyState) {
    client
      .getItem<CustomerFeedbackSurveyState>(key)
      .then((response) => setState(response || {status: 'unknown', date: new Date()}))
      .catch(() => setState({status: 'error', date: new Date()}))
      .finally(() => setIsLoading(false))
  }

  const dismissBanner = () => {
    const state: CustomerFeedbackSurveyState = {status: 'dismissed', date: new Date()}
    client
      .setItem(key, state)
      .then(() => setState(state))
      .catch(() => setState({status: 'error', date: new Date()}))
  }

  return {surveyState, dismissBanner, isLoading}
}
