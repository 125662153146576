import {ErrorBoundary, Typography} from '@hconnect/uikit'
import {Box, LinearProgress} from '@material-ui/core'
import React from 'react'

import {WidgetSubTitle} from './WidgetSubTitle'
import {WidgetTitle} from './WidgetTitle'

interface Props {
  title: string
  subTitle?: string | null
  tooltipInfo?: string
  titleProps?: any
  errorBoundaryProps?: any
  'data-test-id': string
  minified?: boolean
  loading?: boolean
  SubTitleWithDropdown?: React.ReactNode
  children: React.ReactNode
}
export const Widget: React.FC<Props> = ({
  title,
  subTitle = null,
  tooltipInfo = null,
  errorBoundaryProps = {},
  'data-test-id': dataTestId,
  minified = false,
  loading = false,
  SubTitleWithDropdown,
  children
}) => {
  if (loading) {
    return (
      <Box
        border="1px solid rgba(255, 255, 255, 0.15)"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        bgcolor="rgba(0, 0, 0, 0.3)"
        height={minified ? 120 : '100%'}
        paddingLeft={minified ? 2 : 3}
        paddingRight={minified ? 2 : 3}
        paddingBottom={minified ? 2 : 3}
        paddingTop={0}
        data-test-id={dataTestId}
        marginBottom={3}
        borderRadius={4}
      >
        <LinearProgress data-test-id="widget-loader" />
      </Box>
    )
  }

  return (
    <Box
      border="1px solid rgba(255, 255, 255, 0.15)"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      bgcolor="rgba(0, 0, 0, 0.3)"
      height="100%"
      paddingLeft={minified ? 2 : 3}
      paddingRight={minified ? 2 : 3}
      paddingBottom={minified ? 2 : 3}
      paddingTop={0}
      data-test-id={dataTestId}
      marginBottom={3}
      borderRadius={4}
    >
      <Box display="flex" flexDirection="column" paddingTop={minified ? 2 : 3}>
        <WidgetTitle
          title={title}
          subTitle={subTitle}
          tooltipInfo={tooltipInfo}
          dataTestId={dataTestId}
          minified={minified}
          SubTitleWithDropdown={SubTitleWithDropdown}
        />
        {!minified ? <WidgetSubTitle subTitle={subTitle} dataTestId={dataTestId} /> : null}
        <Box p={1} />
      </Box>
      <ErrorBoundary {...errorBoundaryProps}>
        <Typography component="div" variant="body2" color="secondary">
          {children}
        </Typography>
      </ErrorBoundary>
    </Box>
  )
}
