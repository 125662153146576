import {Logos} from '@hconnect/uikit'
import HubLogoSvg from '@hconnect/uikit/src/common/assets/Hub-Logo.svg'
import {makeStyles} from '@material-ui/core/styles'
import React, {FC} from 'react'
import {Link} from 'react-router-dom'

import {useBranding} from '../Organisms/Branding'

const useStyles = makeStyles((theme) => ({
  logos: {
    margin: `0 ${theme.spacing(2)}px 0 0`,
    fontSize: '1.145em'
  },
  withoutBrandingLogo: {
    fontSize: '1.145em',
    // marginLeft: theme.spacing(2.5)
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}))

const HeaderLogo: FC<{withoutBranding?: boolean}> = ({withoutBranding = false}) => {
  const {logos, withoutBrandingLogo} = useStyles()
  const {nationalLogoUrl, NationalLogo, localLogoUrl, LocalLogo} = useBranding()

  return (
    <Link to="/">
      <Logos className={withoutBranding ? withoutBrandingLogo : logos}>
        <img src={HubLogoSvg} alt="Hub" data-test-id="logo-hub" />
        {nationalLogoUrl && !withoutBranding && <NationalLogo data-test-id="logo-national" />}
        {localLogoUrl && !withoutBranding && <LocalLogo data-test-id="logo-local" />}
      </Logos>
    </Link>
  )
}

export default HeaderLogo
