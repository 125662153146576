import {Box} from '@mui/material'
import classNames from 'classnames'
import React from 'react'
import {makeStyles} from 'tss-react/mui'

import Content from './Content'

export const useStyles = makeStyles()((theme) => ({
  overlay: {
    position: 'fixed',
    top: 66,
    bottom: 0,
    right: 0,
    width: '100%',
    overflowY: 'auto',
    opacity: 0,
    pointerEvents: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    transition: 'opacity, top',
    transitionDuration: '.3s',
    transitionTimingFunction: 'ease',
    [theme.breakpoints.down('sm')]: {
      top: '70px'
    }
  },
  overlayVisible: {
    opacity: 1,
    pointerEvents: 'auto'
  },

  contentContainer: {
    width: '360px',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 52px)',
      marginRight: '28px'
    }
  }
}))

type Props = {
  isVisible: boolean
  overlayClassName?: string
  contentClassName?: string
  children: React.ReactNode
  'data-test-id'?: string
  onClick?: () => void
}

export const Overlay: React.FC<Props> = ({
  isVisible = false,
  overlayClassName,
  contentClassName,
  children,
  'data-test-id': dataTestId = 'overlay',
  onClick
}) => {
  const {
    classes: {overlay, overlayVisible, contentContainer}
  } = useStyles()
  return (
    <Content
      className={classNames(overlayClassName, overlay, {
        [overlayVisible]: isVisible
      })}
      data-test-id={dataTestId}
      data-value={isVisible ? 'open' : 'closed'}
      onClick={onClick}
    >
      <Box
        className={classNames(contentClassName, contentContainer)}
        sx={{
          marginRight: {xs: 3, sm: 4, md: 6, lg: 7},
          marginLeft: 'auto'
        }}
      >
        {children}
      </Box>
    </Content>
  )
}
