import {Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  errorTitle: {
    fontSize: 100,
    textTransform: 'uppercase'
  }
}))

export const PermissionDeniedError: FC<{}> = () => {
  const classes = useStyles()
  const {t} = useTranslation()

  return (
    <div className={classes.container}>
      <div>
        <Typography variant="h2" color="textPrimary" className={classes.errorTitle}>
          {t('error.permissionDenied.title')}
        </Typography>
        <Typography variant="body1" color="secondary">
          <b>{t('error.permissionDenied.description')}</b>
          <br />
          {t('error.permissionDenied.reason')}
        </Typography>
      </div>
    </div>
  )
}

export default PermissionDeniedError
