import {makeStyles} from '@material-ui/core'

export const useSMFilterStyles = makeStyles(() => ({
  applyButton: {
    width: '100%'
  },
  closeIcon: {
    border: 'none',
    boxShadow: 'none',
    '&:hover': {
      border: 'none',
      boxShadow: 'none',
      background: 'transparent'
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1.5rem'
    }
  },
  filter: {
    '& div': {
      width: '100%'
    }
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  overlay: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    zIndex: 500
  },
  overlayContent: {
    padding: 0,
    position: 'relative',
    left: 0,
    transitionProperty: 'left',
    transitionDuration: '.5s',
    height: '100%'
  }
}))
