import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import {Button} from '@mui/material'

interface ViewDetailsButtonProps {
  dataTestId?: string
  value: string
  onClick: (event) => void
}

export const ViewDetailsButton = ({onClick, dataTestId, value}: ViewDetailsButtonProps) => (
  <Button
    startIcon={<ArrowForwardIcon />}
    onClick={(event) => onClick(event)}
    style={{
      width: '100%',
      borderRadius: 6,
      backgroundColor: '#fff',
      height: 48,
      color: '#016AD4',
      border: '1px solid #016AD4',
      textTransform: 'none',
      fontSize: 16,
      padding: '10px 12px 11px 12px'
    }}
    data-test-id={dataTestId}
  >
    {value}
  </Button>
)
