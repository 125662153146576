import {makeStyles} from 'tss-react/mui'

const bannerBackground = '#77adca'

export const useBannerStyles = makeStyles()(() => ({
  container: {
    background: bannerBackground,
    color: 'white',
    padding: '8px 16px',
    marginBottom: '-10px',
    position: 'relative',
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'space-between',

    '&::after': {
      zIndex: '1',
      position: 'absolute',
      top: '90%',
      left: '26px',
      content: '""',
      width: 0,
      height: 0,
      borderTop: `solid 8px ${bannerBackground}`,
      borderLeft: 'solid 8px transparent',
      borderRight: 'solid 8px transparent'
    }
  },
  closeButton: {
    cursor: 'pointer'
  }
}))
