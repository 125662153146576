import {Box, Typography} from '@material-ui/core'
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined'
import {t} from 'i18next'
import React from 'react'

import {useAccountMenuStyles} from './AccountMenuStyles'
import {SubMenu} from './SubMenu'

type AccountsMenuProps = {
  accounts: string[]
  openSubMenu: (menu: JSX.Element | null) => void
}

export const AccountsMenu: React.FC<AccountsMenuProps> = ({accounts, openSubMenu}) => {
  const classes = useAccountMenuStyles()

  const accountElements = accounts.map((account: string) => (
    <Typography key={account} className={classes.account}>
      {account}
    </Typography>
  ))

  const menuItem = (
    <Box sx={{display: 'flex'}}>
      <AccountBoxOutlinedIcon className={classes.icon} />
      <Typography>{t('accountMenu.accounts')}</Typography>
      <Typography>{` (${accounts.length})`}</Typography>
    </Box>
  )

  if (accounts.length <= 3) {
    return (
      <Box data-test-id="accounts-menu" className={classes.item} sx={{display: 'block'}}>
        <Typography className={classes.accountsHeader}>{t('accountMenu.accounts')}</Typography>
        {accountElements}
      </Box>
    )
  } else {
    return (
      <SubMenu
        title={t('accountMenu.accounts')}
        icon={<AccountBoxOutlinedIcon className={classes.icon} />}
        menuItem={menuItem}
        subMenuItems={accountElements}
        subMenuItemsClasses={classes.accountsElements}
        openSubMenu={openSubMenu}
        data-test-id="accounts-menu"
      />
    )
  }
}
