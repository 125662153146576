import React from 'react'
import {useTranslation} from 'react-i18next'

import EnvAppInfoContainer from '../../common/appInfo/EnvAppInfoContainer'
import {useBranding} from '../../Organisms/Branding'
import {LegalLinks, LegalLinkType} from '../LegalLinks'

export const Copyright: React.FC = () => {
  const {t} = useTranslation()
  const year = new Date().getFullYear()

  const {nationalBrandName, nationalUrl} = useBranding()

  const versionAndCopyright: LegalLinkType[] = [
    {
      key: 'copyright',
      title: t('accountMenu.legal.copyright', {year, nationalBrandName}),
      endpoint: nationalUrl,
      isInternal: false
    },
    {
      key: 'version',
      title: <EnvAppInfoContainer />,
      href: '#',
      isInternal: true
    }
  ]
  return <LegalLinks links={versionAndCopyright} />
}
