import {styled, Typography} from '@mui/material'
import React from 'react'

interface AddressProps {
  street: string
  city: string
  state: string
  postalCode: string
  showState: boolean
}

const Div = (props) => <Typography component="div" {...props} />

export const ZipAndCityLabel = styled(Div)(() => {
  const style: any = {
    root: {
      fontSize: 14,
      fontWeight: '500',
      color: 'rgba(0, 54, 79, 0.6)'
    }
  }
  return style
})

export const Address = ({street, city, state, postalCode, showState}: AddressProps) => (
  <>
    <ZipAndCityLabel variant="body2">{street}</ZipAndCityLabel>
    <ZipAndCityLabel variant="body2">
      {city}, {showState && state} {postalCode}
    </ZipAndCityLabel>
  </>
)
