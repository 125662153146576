import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import {Button, Theme} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router'
import {makeStyles} from 'tss-react/mui'

export interface ResponsivePageHeaderProps {
  title: string
  subTitle?: string
  backNavigation?: string
  backNavigationDataTestId?: string
  toolbar?: React.ReactNode
}

export const useStyles = makeStyles()((theme: Theme) => ({
  header: {
    padding: theme.spacing(2, 0),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  backNavigation: {
    color: 'rgba(255,255,255,0.6)',
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 500,
    padding: 0
  },
  title: {
    color: 'white',
    fontWeight: 700,
    fontSize: '28px',
    lineHeight: '34px'
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
}))

export const ResponsivePageHeader: React.FC<ResponsivePageHeaderProps> = ({
  title,
  subTitle,
  backNavigation,
  backNavigationDataTestId,
  toolbar
}) => {
  const {classes} = useStyles()
  const {t} = useTranslation()
  const history = useHistory()
  const goBack = () => history.goBack()

  return (
    <div className={classes.header}>
      <div>
        {backNavigation && (
          <Button
            variant="text"
            startIcon={<ArrowBackIosNewIcon sx={{height: 12}} />}
            onClick={goBack}
            className={classes.backNavigation}
            data-test-id={backNavigationDataTestId}
          >
            {t('layout.backTo', {pageTitle: backNavigation})}
          </Button>
        )}
        <div className={classes.title}>{title}</div>
        {subTitle}
      </div>
      {toolbar && <div className={classes.toolbar}>{toolbar}</div>}
    </div>
  )
}
