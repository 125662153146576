import {Box, Theme} from '@mui/material'
import {makeStyles} from 'tss-react/mui'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import {TFunction} from 'i18next'
import {OutlinedButton} from '../Buttons/OutlinedButton'
import {Order} from '../../Pages/Order/Order.types'
import {Delivery} from '../../Pages/Finance/Invoices/Delivery.types'
import {findDocuments} from '../../Pages/Order/Order.utils'

export const styles = makeStyles()((theme: Theme) => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1, 0)
  }
}))

type IDownloadAllDeliveryNotes = {
  t: TFunction
  bulkDownload: (
    deliveryIds: string[],
    orderIds: string[],
    ticketsCount: number,
    combined: boolean
  ) => void
  order: Order
}

export const DownloadAllDeliveryNotes = ({t, bulkDownload, order}: IDownloadAllDeliveryNotes) => {
  const {classes} = styles()
  const deliveryIds = order.deliveries?.map((item: Delivery) => item.deliveryId) ?? []
  if (deliveryIds.length === 0) return null

  const areDeliveryDocumentsAvailable = order.deliveries?.some((delivery: Delivery) => {
    const {deliveryNote, proofOfDelivery} = findDocuments(delivery)
    return !!proofOfDelivery || !!deliveryNote
  })

  if (!areDeliveryDocumentsAvailable) return null

  const handleClick = () => {
    bulkDownload(deliveryIds, [order.orderId], 0, false)
  }

  return (
    <Box className={classes.buttonContainer}>
      <OutlinedButton
        startIcon={<FileDownloadIcon />}
        onClick={handleClick}
        dataTestId="DownloadAllDeliveriesNotes"
        value={t('order.downloadAllDeliveryNotes')}
      />
    </Box>
  )
}
