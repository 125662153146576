import {Typography} from '@hconnect/uikit'
import {Box, Tooltip} from '@material-ui/core'
import {InfoOutlined} from '@material-ui/icons'
import React from 'react'

interface WidgetTitleProps {
  title: string
  tooltipInfo?: string | null
  dataTestId: string
  minified?: boolean
  subTitle?: string | null
  SubTitleWithDropdown?: React.ReactNode
}

export const WidgetTitle: React.FC<WidgetTitleProps> = ({
  title,
  tooltipInfo,
  dataTestId,
  minified = false,
  SubTitleWithDropdown
}) => {
  if (tooltipInfo) {
    if (minified) {
      return (
        <Box display="flex" flexDirection="column">
          <Typography
            customVariant="formLabel"
            color="textSecondary"
            data-test-id={dataTestId ? `${dataTestId}-title` : ''}
          >
            {title}
          </Typography>
          <Box mr={0.5} />
          <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
            <Tooltip title={tooltipInfo} placement="top-start" color="white">
              <InfoOutlined color="secondary" height={18} />
            </Tooltip>
            <Box mr={0.5} />
            {SubTitleWithDropdown}
          </Box>
        </Box>
      )
    }
    return (
      <Box display="flex" alignItems="center">
        <Typography
          customVariant="formLabel"
          color="textSecondary"
          data-test-id={dataTestId ? `${dataTestId}-title` : ''}
        >
          {title}
        </Typography>
        <Box p={0.5} />
        <Tooltip title={tooltipInfo} placement="right" color="white">
          <InfoOutlined color="secondary" height={minified ? 18 : 'auto'} />
        </Tooltip>
      </Box>
    )
  }
  return (
    <Typography
      customVariant="formLabel"
      color="textSecondary"
      data-test-id={dataTestId ? `${dataTestId}-title` : ''}
    >
      {title}
    </Typography>
  )
}
