import {User} from '@hconnect/apiclient'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import {AppState} from '../../../../Root.store'
import {CustomerFeedback} from '../types'
import {useRef} from 'react'
import {generateGuid} from '../../../../Organisms/OrderIntake/utils'
import {getAvailableBusinessLines, getRolesList} from '../../../../Roles/Roles.utils'
import {Role} from '../../../../Roles/Roles.types'

export const useCustomerFeedbackSurveyAnalytics = (roles: Role[] | undefined) => {
  const user = useSelector<AppState, User | null>((state) => state.userProfile.userProfile)
  const correlationId = useRef(generateGuid())
  const {i18n} = useTranslation()

  const track = (eventName: string, props = {}) => {
    if (!user) return

    const isInternalUser = user?.isInternal || false
    const rolesList = getRolesList(roles ?? [])
    const businessLines = roles ? getAvailableBusinessLines(roles) : []

    const eventValue = {
      ...props,
      product: 'hub',
      appVersion: process.env.REACT_APP_VERSION,
      userId: user.user_id,
      resolvedLanguage: i18n.resolvedLanguage,
      timestamp: new Date(),
      country: user?.country,
      correlationId: correlationId.current,
      isInternalUser,
      roles: rolesList,
      businessLines
    }
    trackEvent(eventName, eventValue)
  }

  const trackViewed = () => track('hubCustomerFeedbackSurveyViewed')
  const trackDismissed = () => track('hubCustomerFeedbackSurveyDismissed')
  const trackAnswered = (question: number, rating: number) =>
    track(`hubCustomerFeedbackSurveyQuestion${question}Answered`, {rating: rating.toString()})
  const trackSubmitted = (feedback: CustomerFeedback[]) => {
    let eventValue = {}
    feedback.forEach((f) => {
      eventValue = {
        ...eventValue,
        [`rating_q${f.id}`]: f.sentiment?.toString() ?? '',
        [`comment_q${f.id}`]: f.comment,
        [`question_q${f.id}`]: f.question
      }
    })
    track('hubCustomerFeedbackSurveySubmitted', eventValue)
  }

  return {trackViewed, trackDismissed, trackAnswered, trackSubmitted}
}
