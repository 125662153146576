import {Tooltip, TooltipProps} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import React from 'react'

const useTooltipStyles = makeStyles({
  tooltip: {
    fontSize: 14,
    maxWidth: 'unset'
  }
})

const StatusTooltip: React.FC<TooltipProps> = (props) => {
  const {tooltip} = useTooltipStyles()
  return <Tooltip classes={{tooltip}} {...props} />
}

export default StatusTooltip
