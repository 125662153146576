import {Box} from '@material-ui/core'
import React from 'react'

export const DocumentActionsBar = ({children}: {children: React.ReactNode}) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      bgcolor="rgb(247, 247, 247)"
      p={1.25}
      paddingLeft={4}
      paddingRight={4}
    >
      {children}
    </Box>
  )
}
