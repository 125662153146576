import {Box, Typography} from '@mui/material'
import {TFunction} from 'i18next'
import {styles} from './styles'
import moment from 'moment'
import {timeFormatter} from '../../OrderIntake/components/TimeScroller/TimeScroller.utils'

type TETAStatus = {
  latestDeliveryTime: string | Date | undefined
  estimatedTimeOfArrival: string | Date | undefined
  t: TFunction
}

export const ETAStatus = ({latestDeliveryTime, estimatedTimeOfArrival, t}: TETAStatus) => {
  const {classes} = styles()
  const eta = moment(estimatedTimeOfArrival)
  const latest = moment(latestDeliveryTime)

  const deliveryETAStatus = {
    onTime: {message: t('ETATrucks.statusMessage.onTime'), status: 'onTime'},
    delayed: {message: timeFormatter(eta), status: 'delayed'},
    unknown: {message: t('ETATrucks.statusMessage.unknown'), status: 'unknown'}
  }

  if (!latestDeliveryTime || !estimatedTimeOfArrival)
    return (
      <Typography
        variant="body2"
        className={`${classes.etaStatus} ${classes[deliveryETAStatus.unknown.status]}`}
      >
        {deliveryETAStatus.unknown.message}
      </Typography>
    )

  const getStatusMessage = () => {
    if (eta.isBefore(latest) || eta.isSame(latest)) return deliveryETAStatus.onTime
    if (eta.isAfter(latest)) return deliveryETAStatus.delayed
    return deliveryETAStatus.unknown
  }
  const statusMessage = getStatusMessage()

  return (
    <Box display="flex" flexDirection="column">
      <Typography
        variant="body2"
        className={`${classes.etaStatus} ${classes[statusMessage.status]}`}
        data-test-id={`eta-status-${statusMessage.status}`}
      >
        {statusMessage.message}
      </Typography>
      {statusMessage.status === 'delayed' && (
        <Typography
          variant="body2"
          className={`${classes.etaStatus} `}
          data-test-id={`eta-${statusMessage.status}-terms`}
        >
          {t('ETATrucks.etaDelayTerms')}
        </Typography>
      )}
    </Box>
  )
}
