import {Typography} from '@hconnect/uikit'
import {styled} from '@mui/material'

interface ResponsiveComponentType {
  smallScreen?: boolean
}
export const BoldText = styled(Typography)<ResponsiveComponentType>(() => ({
  fontWeight: 600
}))

export const SmallerHeader = styled(Typography)<ResponsiveComponentType>(({smallScreen}) => ({
  fontSize: smallScreen ? 22 : 24,
  fontWeight: 600,
  lineHeight: smallScreen ? '28px' : '31px',
  color: '#00274D'
}))

export const TinyDescription = styled(Typography)<ResponsiveComponentType>(({smallScreen}) => ({
  fontSize: smallScreen ? 10 : 12,
  fontWeight: 400,
  fontStyle: 'italic',
  lineHeight: smallScreen ? '16px' : '18px',
  color: '#54708C'
}))
