import {Typography} from '@hconnect/uikit'
import {Box} from '@material-ui/core'
import Info from '@material-ui/icons/Info'
import React from 'react'

interface InfoTextWithIconProps {
  text: string | null
  'data-test-id'?: string
}

export const InfoTextWithIcon: React.FC<InfoTextWithIconProps> = ({
  text,
  'data-test-id': dataTestId
}) => {
  if (!text) return null
  return (
    <Box display="flex" alignSelf="center" alignItems="center">
      <Info fontSize="medium" color="secondary" />
      <Box p={0.5} />
      <Typography variant="caption" color="secondary" data-test-id={dataTestId}>
        {text}
      </Typography>
    </Box>
  )
}
