import {Typography} from '@hconnect/uikit'
import {Box, makeStyles, Button} from '@material-ui/core'
import {HourglassEmpty, Check, Error} from '@material-ui/icons'
import React from 'react'
import {useTranslation} from 'react-i18next'

interface InfoBannerProps {
  isVisible: boolean
  isError: boolean
  isPending: boolean
  successText: string
  errorText: string
  pendingText: string
  'data-test-id'?: string
  handleClick: () => void
}

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: 'rgba(29, 196, 99, .1)',
    minHeight: theme.spacing(7),
    padding: `${theme.spacing(1.5)}px ${theme.spacing(4)}px`
  },
  errorContainer: {
    backgroundColor: 'rgba(255, 79, 0, .1)',
    minHeight: theme.spacing(7),
    padding: `${theme.spacing(1.5)}px ${theme.spacing(4)}px`
  },
  spin: {
    backgroundColor: 'red',
    animation: 'spin 4s linear infinite',
    transform: 'rotate(180deg)'
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(180deg)'
    }
  }
}))

export const InfoBanner: React.FC<InfoBannerProps> = ({
  isVisible,
  isPending,
  successText,
  errorText,
  isError,
  pendingText,
  'data-test-id': dataTestId,
  handleClick
}) => {
  const classes = useStyles()
  const {t} = useTranslation()
  if (!isVisible) {
    return null
  }

  if (isError) {
    return (
      <Box display="flex" alignItems="center" width="100%" className={classes.errorContainer}>
        <Error />
        <Box p={1} />
        <Typography variant="h4" color="textPrimary" data-test-id={dataTestId}>
          {errorText}
        </Typography>
      </Box>
    )
  }

  if (isPending) {
    return (
      <Box display="flex" alignItems="center" width="100%" className={classes.container}>
        <HourglassEmpty />
        <Box p={1} />
        <Typography variant="h4" color="textPrimary" data-test-id={dataTestId}>
          {pendingText}
        </Typography>
      </Box>
    )
  }

  return (
    <Box display="flex" alignItems="center" width="100%" className={classes.container}>
      <Check />
      <Box p={1} />
      <Typography variant="h4" color="textPrimary" data-test-id={dataTestId}>
        {successText}
      </Typography>
      <Box p={1} />
      <Button
        data-test-id={`${dataTestId}-button`}
        onClick={() => {
          handleClick()
        }}
        color="primary"
      >
        {t('reload')}
      </Button>
    </Box>
  )
}
