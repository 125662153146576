import {User} from '@hconnect/apiclient'
import moment from 'moment'
import {useSelector} from 'react-redux'

import {AppState} from '../../../../Root.store'

import {useCustomerFeedbackSurveyState} from './useCustomerFeedbackSurveyState'

const minimumUserAgeWeeks = 4
const surveyPromptIntervalMonths = 4

export const useCustomerFeedbackSurveyVisibility = (isDismissed?: boolean) => {
  const user = useSelector<AppState, User | null>((state) => state.userProfile.userProfile)
  const {surveyState, isLoadingSurveyStatus} = useCustomerFeedbackSurveyState()
  if (isDismissed) return {showSurvey: false}

  const canShowSurveyForUser = () => {
    if (!user) return false
    if (!user.createdOn) return true

    const userAgeWeeks = moment().diff(moment(user.createdOn), 'weeks', true)
    return userAgeWeeks >= minimumUserAgeWeeks
  }

  const canShowSurveyForStatus = () => {
    if (!surveyState || surveyState.status === 'error') return false
    if (surveyState.status === 'unknown') return true
    const lastStateAge = moment().diff(moment(surveyState.date), 'months', true)
    return (
      (surveyState.status === 'dismissed' && lastStateAge >= surveyPromptIntervalMonths) ||
      (surveyState.status === 'submitted' && lastStateAge >= surveyPromptIntervalMonths)
    )
  }

  const showSurvey = !isLoadingSurveyStatus && canShowSurveyForUser() && canShowSurveyForStatus()

  return {showSurvey}
}
