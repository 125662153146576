/* eslint-disable complexity */
import {FilterBar, HorizontalAlignment, TextButton} from '@hconnect/uikit'
import {Box} from '@material-ui/core'
import {OpenInNew} from '@material-ui/icons'
import React from 'react'

interface FilterBarLink {
  linkAddress: string
  linkLabelTranslationKey: string
  t: any
}

export const FilterBarLink: React.FC<FilterBarLink> = ({
  linkAddress,
  t,
  linkLabelTranslationKey
}) => {
  return (
    <FilterBar toolbarProps={{color: 'grey', horizontalAlignment: HorizontalAlignment.End}}>
      <TextButton
        variant="text"
        onClick={() => {
          window.open(linkAddress)
        }}
      >
        <Box display="flex" alignItems="center" lineHeight={0}>
          <Box marginRight={0.5}>
            <OpenInNew />
          </Box>
          {t(linkLabelTranslationKey)}
        </Box>
      </TextButton>
    </FilterBar>
  )
}
