import MarkAsUnreadOutlinedIcon from '@mui/icons-material/MarkAsUnreadOutlined'
import {Box, Divider} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useAccountMenuStyles} from '../../AccountMenu/AccountMenuStyles'
import {SubMenu} from '../../AccountMenu/SubMenu'
import {CustomerFeedbackPanel} from '../CustomerFeedbackPanel'
import {useFeedbackProgrammeVisibility, useImprovementProgramme} from '../hooks'

import {useProfileStyles} from './styles'
import {SubscribedPopover} from './SubscribedPopover'

type TCustomerPanelProfile = {
  openSubMenu: (menu: JSX.Element | null) => void
  isSubMenuOpen?: JSX.Element | null
}

export const CustomerPanelProfile: React.FC<TCustomerPanelProfile> = ({
  openSubMenu,
  isSubMenuOpen
}) => {
  const {t} = useTranslation()
  const {classes} = useProfileStyles()
  const accountMenuStyles = useAccountMenuStyles()

  const [isSubscribingPopupInProcess, setIsSubscribingPopupInProcess] = useState<boolean>(false)

  const {data: improvementProgrammeData} = useImprovementProgramme()

  const isSubscribed = improvementProgrammeData?.isSubscribed

  useEffect(() => {
    if (!isSubMenuOpen) setIsSubscribingPopupInProcess(false)
  }, [isSubMenuOpen])

  const handleClose = (event: Event | React.SyntheticEvent) => {
    event.stopPropagation()
    isSubscribingPopupInProcess && setIsSubscribingPopupInProcess(false)
    openSubMenu(null)
  }

  const subMenu = (
    <Box className={classes.profileContainer}>
      {isSubscribed && !isSubscribingPopupInProcess ? (
        <SubscribedPopover close={handleClose} />
      ) : (
        <CustomerFeedbackPanel
          close={handleClose}
          isSubscribingProcessOn={(val) => setIsSubscribingPopupInProcess(val)}
          isFromProfile
          entryPoint="userProfile"
        />
      )}
    </Box>
  )

  const menuItem = (
    <>
      <Box className={classes.profileItem} data-test-id="feedbackProgramProfileItem">
        <MarkAsUnreadOutlinedIcon className={accountMenuStyles.icon} />
        {t('customerFeedbackPanel.header')}
        {isSubscribed && (
          <span className={classes.subscribedLabel} data-test-id="profileItemSubscriptionLabel">
            {t('customerFeedbackPanel.subscribed')}
          </span>
        )}
      </Box>
    </>
  )

  if (!useFeedbackProgrammeVisibility()) return null

  return (
    <>
      <SubMenu
        title={t('customerFeedbackPanel.header')}
        icon={<MarkAsUnreadOutlinedIcon className={accountMenuStyles.icon} />}
        menuItem={menuItem}
        subMenuContent={subMenu}
        openSubMenu={openSubMenu}
        dividers
        dataTestId="customerFeedbackPanel"
      />
      <Divider className={accountMenuStyles.divider} />
    </>
  )
}
