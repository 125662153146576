import {Divider as MUIDivider} from '@mui/material'
import {Theme} from '@mui/material'
import {makeStyles} from 'tss-react/mui'

export const styles = makeStyles()((theme: Theme) => ({
  divider: {
    margin: theme.spacing(1, 0)
  }
}))

type IDivider = {
  classNames?: string
}
export const Divider = ({classNames}: IDivider) => {
  const {classes} = styles()
  return <MUIDivider className={`${classNames} ${classes.divider}`} />
}
