/* eslint-disable react/no-unescaped-entities */
import {Typography} from '@hconnect/uikit'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import CloseIcon from '@mui/icons-material/Close'
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  styled
} from '@mui/material'
import type {TFunction} from 'i18next'
import React from 'react'
import {Trans} from 'react-i18next'

import C60LogoBig from '../../../Assets/c60_logo_big.svg'
import C60ThankYou from '../../../Assets/c60_thank_you.png'

const ErrorAlert = styled(Alert)({
  backgroundColor: 'transparent',
  borderRadius: 0.5,
  border: '1px solid #EAEDF0',
  borderLeft: '4px solid #DA0901',
  boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.05)'
})

interface C60DialogType {
  fullScreen: boolean
  dialogOpen: boolean
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  leadCreated: {status?: 'success' | 'error'; error?: any}
  t: TFunction
  onConfirm: () => void
  onClose: () => void
}
export const C60Dialog: React.FC<C60DialogType> = ({
  fullScreen,
  dialogOpen,
  setDialogOpen,
  leadCreated,
  t,
  onConfirm,
  onClose
}) => (
  <Dialog
    fullWidth
    maxWidth="sm"
    fullScreen={fullScreen}
    open={dialogOpen}
    onClose={() => setDialogOpen(false)}
    data-test-id="c60-lead-dialog"
    aria-labelledby="c60-dialog-title"
  >
    {leadCreated.status === 'success' ? null : (
      <DialogTitle id="c60-dialog-title" data-test-id="c60-dialog-title">
        {t('lead.c60.dialogTitle')}
      </DialogTitle>
    )}
    <DialogContent sx={{mt: 2}}>
      {leadCreated.status === 'success' ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-around"
          data-test-id="c60-lead-dialog-success"
        >
          <Box>
            <img src={C60ThankYou} width={215} alt="" />
          </Box>
          <Box mt={2.5} pl={5} pr={5}>
            <DialogContentText>
              <Typography variant="body1" align="center">
                {t('lead.c60.dialogContentConfirmed')}
              </Typography>
            </DialogContentText>
          </Box>
        </Box>
      ) : (
        <Grid container spacing={12}>
          <Grid item md={2} lg={2} xl={2}>
            <Box data-test-id="c60-lead-dialog-logo">
              <img src={C60LogoBig} width="78px" alt="" />
            </Box>
          </Grid>
          <Grid item md={10} lg={10} xl={10}>
            <Box display="flex" flexDirection="column" gap={1.75} ml={1}>
              <DialogContentText>{t('lead.c60.dialogContent1')}</DialogContentText>
              <DialogContentText>
                <Trans i18nKey="lead.c60.dialogContent2">
                  Ready mix concrete producers use C60's software to identify savings opportunities
                  across their entire business.
                  <strong>Imagine what you could do with an extra $1 per cubic yard.</strong>
                </Trans>
              </DialogContentText>
              <DialogContentText>{t('lead.c60.dialogContent3')}</DialogContentText>
            </Box>
          </Grid>
        </Grid>
      )}
    </DialogContent>
    <DialogActions sx={{mt: 7.75}}>
      <Grid container spacing={12}>
        <Grid item md={2} lg={2} xl={2} />
        <Grid item md={10} lg={10} xl={10}>
          {leadCreated.status === 'error' ? (
            <ErrorAlert icon={false} sx={{mb: 3}}>
              {t('lead.c60.dialogError')}
            </ErrorAlert>
          ) : null}
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={1.5}
            justifyContent={leadCreated.status === 'success' ? 'flex-end' : 'space-between'}
          >
            {leadCreated.status !== 'success' ? (
              <Button
                data-test-id="c60-dialog-confirm-button"
                startIcon={<ArrowForwardIcon />}
                onClick={() => onConfirm()}
                style={{
                  width: '100%',
                  borderRadius: 6,
                  backgroundColor: '#016AD4',
                  height: 48,
                  color: '#FFFFFF',
                  textTransform: 'none',
                  padding: '10px 12px 11px 12px'
                }}
              >
                {t('lead.c60.dialogConfirm')}
              </Button>
            ) : null}
            <Button
              data-test-id="c60-dialog-close-button"
              color="secondary"
              startIcon={<CloseIcon />}
              onClick={() => onClose()}
              style={{
                border: '1px solid rgba(0, 39, 77, 0.1)',
                borderRadius: 6,
                backgroundColor: '#FFFFFF',
                height: 48,
                color: '#016AD4',
                textTransform: 'none',
                width: '100px'
              }}
            >
              {t('lead.c60.dialogClose')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </DialogActions>
  </Dialog>
)
