import {Box, Divider, MenuItem, MenuList, Typography} from '@material-ui/core'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import classNames from 'classnames'
import React from 'react'

import {ReactComponent as RightArrowSvg} from '../../Assets/customerFeedbackBanner/rightArrow.svg'

import {useAccountMenuStyles} from './AccountMenuStyles'

type SubMenuProps = {
  title: string
  icon?: JSX.Element
  menuItem: JSX.Element
  subMenuItems?: JSX.Element[]
  subMenuContent?: JSX.Element
  openSubMenu: (menu: JSX.Element | null) => void
  dividers?: boolean
  subMenuItemsClasses?: string
  dataTestId?: string
}

export const SubMenu: React.FC<SubMenuProps> = ({
  menuItem,
  subMenuItems,
  subMenuContent,
  title,
  icon,
  openSubMenu,
  dividers,
  subMenuItemsClasses,
  dataTestId = 'sub-menu'
}) => {
  const classes = useAccountMenuStyles()

  const subMenu = (
    <MenuList className={classNames(classes.menu, classes.subMenuList)} data-test-id="sub-menu">
      <MenuItem
        className={classes.item}
        onClick={() => openSubMenu(null)}
        data-test-id="sub-menu-close"
      >
        <ArrowBackIosNewIcon className={classes.carrot} />
        <Box sx={{display: 'flex', margin: 'auto'}}>
          {icon && icon}
          {title && <Typography>{title}</Typography>}
        </Box>
      </MenuItem>
      <Divider className={classes.divider} />
      <Box className={subMenuItemsClasses}>
        {subMenuItems?.map((subMenuItem, key) => {
          return (
            <React.Fragment key={key}>
              {dividers && key > 0 && <Divider className={classes.divider} />}
              {subMenuItem}
            </React.Fragment>
          )
        })}
        {subMenuContent}
      </Box>
    </MenuList>
  )

  return (
    <MenuItem
      className={classNames(classes.item, classes.subMenu)}
      onClick={() => openSubMenu(subMenu)}
      data-test-id={dataTestId}
    >
      {menuItem}
      <RightArrowSvg />
    </MenuItem>
  )
}
