import {TextButton} from '@hconnect/uikit'
import {ExtendButtonBase, ButtonTypeMap, Box} from '@material-ui/core'
import {OpenInNew} from '@material-ui/icons'
import React from 'react'

import {trackEvent} from '../common/analytics'

interface OpenInNewTabButtonProps {
  title: string
  link: string
  trackingEventName: string
  trackingEventParams: any
}
export const OpenInNewTabButton: React.FC<
  Partial<ExtendButtonBase<ButtonTypeMap<{}, 'button'>>> & OpenInNewTabButtonProps
> = ({title, link, trackingEventName, trackingEventParams, ...props}) => {
  return (
    <Box>
      <TextButton
        color="secondary"
        variant="text"
        startIcon={<OpenInNew />}
        onClick={() => {
          window.open(link, '_blank')
          trackEvent(trackingEventName, {trackingEventParams})
        }}
        {...props}
      >
        {` ${title}`}
      </TextButton>
    </Box>
  )
}
