import {Theme} from '@mui/material'
import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme: Theme) => ({
  c60Container: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '18px',
    color: '#00274D'
  },
  description: {
    padding: theme.spacing(2, 3),
    borderRadius: '8px',
    position: 'relative',
    zIndex: 1,
    boxShadow: '0px 4px 6px 0px rgba(0, 0, 0, 0.05)',
    background:
      'linear-gradient(white, white) padding-box, linear-gradient(to top, #EAEDF0, transparent) border-box',
    border: '1px solid transparent'
  },
  amount: {color: '#6A9142', fontSize: '28px', fontWeight: 600, padding: theme.spacing(2, 0)},
  hint: {
    color: '#54708C',
    fontSize: '10px',
    fontWeight: 400,
    lineHeight: '15px',
    display: 'flex',
    alignItems: 'start',
    paddingTop: theme.spacing(1.5)
  },
  bulbIcon: {
    height: '48px',
    width: '48px',
    position: 'relative',
    top: '-10px',
    marginRight: theme.spacing(1)
  },
  learnMore: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '18px',
    marginTop: theme.spacing(-1),
    position: 'relative',
    zIndex: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(2, 3),
    paddingTop: theme.spacing(3),
    color: '#019AAA',
    background: 'linear-gradient(180deg, #019aaa00 0%, #019aaa61 100%)',
    boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.05)',
    borderRadius: '0px 0px 8px 8px',
    border: '1px solid var(--light-border, #EAEDF0)',
    '& button': {
      padding: 0,
      paddingTop: theme.spacing(1),
      color: '#016AD4',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '18px'
    }
  },

  detailsPageStyles: {
    margin: theme.spacing(-2, -2, 0, -2)
  }
}))
