import {useLegalAcceptance} from '@hconnect/common/hooks/legal/useLegalAcceptance'
import {
  useLatestDocumentVersion,
  useLegalDocumentAcceptanceRequired
} from '@hconnect/common/hooks/legal/useLegalDocumentsVersions'
import {getBrowserLanguage} from '@hconnect/common/utils'
import {LegalDocumentType, TermsAndPrivacyModal} from '@hconnect/uikit'
import {ReactComponent as HubLogoSvg} from '@hconnect/uikit/src/common/assets/Hub-Logo.svg'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'

import {api} from '../App.global'
import {AppState} from '../Root.store'
import {fetchUserProfile} from '../UserProfile/UserProfile.actions'

import {HubPrivacyBanner} from './HubPrivacyBanner'

export const HubTermsAndPrivacyModal = () => {
  const user = useSelector((state: AppState) => state?.userProfile?.userProfile)
  const dispatch = useDispatch()
  const {i18n} = useTranslation()

  const country = user?.country?.toLowerCase()

  const isPrivacyPolicyApprovalRequired = useLegalDocumentAcceptanceRequired(
    'dpp',
    country,
    user?.dataPrivacyAcknowledgedVersion
  )
  const isTermsApprovalRequired = useLegalDocumentAcceptanceRequired(
    'tc',
    country,
    user?.termsAcceptedVersion
  )

  const document = isTermsApprovalRequired ? LegalDocumentType.TERMS : LegalDocumentType.NONE

  const latestTcVersionQueryInfo = useLatestDocumentVersion('tc', country?.toLowerCase())
  const latestDppVersionQueryInfo = useLatestDocumentVersion('dpp', country?.toLowerCase())

  const dppVersion = latestDppVersionQueryInfo?.lastAvailableDocumentVersion?.version
  const tcVersion = latestTcVersionQueryInfo?.lastAvailableDocumentVersion?.version
  const preferredLanguage = getBrowserLanguage()

  const {accept} = useLegalAcceptance({
    dppVersion,
    tcVersion,
    authorizedApiClient: api,
    username: user?.user_id,
    mutationOptions: {
      onSuccess: () => {
        if (!user?.user_id) return

        dispatch(fetchUserProfile(user.user_id))
      }
    }
  })

  if (isTermsApprovalRequired) {
    return (
      <TermsAndPrivacyModal
        showFooter
        preferredLanguage={preferredLanguage}
        documentType={document}
        onAccept={() => accept()}
        logo={HubLogoSvg}
        hideAcceptanceTitle={false}
        country={country}
        onLanguageChange={(languageCode: string) => {
          void i18n.changeLanguage(languageCode)
        }}
      />
    )
  }

  if (isPrivacyPolicyApprovalRequired) {
    return <HubPrivacyBanner onClose={() => accept()} />
  }

  return null
}
