import {Box, Typography} from '@mui/material'
import {TFunction} from 'i18next'
import {MaterialLocation, Order, OrderStatus, ShippingType} from '../../Pages/Order/Order.types'
import {Delivery, DeliveryStatus} from '../../Pages/Finance/Invoices/Delivery.types'

import MapOutlinedIcon from '@mui/icons-material/MapOutlined'
import {styles} from './styles'
import {BusinessLineType} from '../../common/types'
import {useETA} from './useETA'
import {useFeaturesState} from '../../Organisms/Features'
import {PageNames} from '../../common/constants'
import {ETAStatus} from './ETAStatus'
import {timeFormatter} from '../../OrderIntake/components/TimeScroller/TimeScroller.utils'
import {useEffect, useState} from 'react'
import {AlertWithLeftBorder} from '../Alert'

type TETADeliveryTrucks = {
  t: TFunction
  order?: Order
  delivery?: Delivery
  pageName: PageNames.ORDERS | PageNames.ORDER_DETAILS
}
type TUseETA = {
  data: MaterialLocation
  error: any
  isLoading: boolean
}

export const ETADeliveryTrucks = ({
  t,
  order,
  delivery: orderDetailsDelivery,
  pageName
}: TETADeliveryTrucks) => {
  const {classes} = styles()
  const {getFeature} = useFeaturesState()
  const [isETAVisible, setIsETAVisible] = useState<boolean>(false)
  const showETAForCEMTruck = getFeature('ShowETAForCEMTruck')
  const {orderStatus, businessLine, shippingType, deliveries, orderId, schedules} = order || {}
  const {scheduleStartDate, scheduleEarliestStartDate} = schedules?.[0] || {}
  const delivery =
    pageName === PageNames.ORDERS
      ? deliveries?.find(
          (delivery) =>
            delivery.deliveryStatus !== DeliveryStatus.Cancelled &&
            delivery.deliveryStatus !== DeliveryStatus.Returned
        )
      : orderDetailsDelivery
  const {deliveryEvents, deliveryStatus, deliveryId} = delivery || {}
  const {loadFinished, plantLeft} = deliveryEvents || {}
  const truckStarted = plantLeft || loadFinished
  const {
    data: {estimatedTimeOfArrival} = {},
    error: etaError,
    isLoading
  } = useETA(isETAVisible, pageName, orderId, deliveryId) as TUseETA

  const checkETAVisibility = () => {
    return (
      showETAForCEMTruck &&
      businessLine === BusinessLineType.CEM &&
      shippingType === ShippingType.Deliver &&
      deliveryStatus === DeliveryStatus.InTransit &&
      (orderStatus === OrderStatus.InProgress ||
        orderStatus === OrderStatus.PartiallyDelivered ||
        orderStatus === OrderStatus.PartiallyShipped ||
        orderStatus === OrderStatus.Shipped)
    )
  }
  useEffect(() => {
    checkETAVisibility() ? setIsETAVisible(true) : setIsETAVisible(false)

    return () => {
      setIsETAVisible(false)
    }
  }, [showETAForCEMTruck, businessLine, shippingType, deliveryStatus, orderStatus])

  if (!delivery) return null
  if (!checkETAVisibility()) return null
  if (!estimatedTimeOfArrival && !isLoading) return null

  const renderETADetails = (title: string, value: string, etaStatus?: React.ReactNode) => (
    <>
      <Typography variant="body1" className={classes.etaSubContainerTitle}>
        {t(title)}
      </Typography>
      <Typography variant="body1" className={classes.etaSubContainerValue}>
        {value}
        {etaStatus}
      </Typography>
    </>
  )

  const renderRequestedTimeBlock = () => {
    if (scheduleEarliestStartDate && scheduleStartDate)
      return `${timeFormatter(scheduleEarliestStartDate)} - ${timeFormatter(scheduleStartDate)}`
    if (scheduleStartDate) return timeFormatter(scheduleStartDate)
    return t('ETATrucks.unavailable')
  }
  const getETAStatus = () => {
    if (isLoading)
      return <Typography className={classes.etaStatus}>{t('ETATrucks.loading')}</Typography>
    if (etaError)
      return <Typography className={classes.etaStatus}>{t('ETATrucks.apiError')}</Typography>
    return (
      <ETAStatus
        latestDeliveryTime={scheduleStartDate}
        estimatedTimeOfArrival={estimatedTimeOfArrival}
        t={t}
      />
    )
  }

  return (
    <Box className={classes.etaContainer} data-test-id="eta-container">
      <Box className={classes.etaTitle}>
        <MapOutlinedIcon />
        <Typography variant="h5" style={{marginLeft: '8px', fontWeight: 600}}>
          {t('ETATrucks.title')}
        </Typography>
      </Box>

      <Box className={classes.container}>
        <Box className={classes.etaSubContainer}>
          {renderETADetails(
            'ETATrucks.plantLeft',
            truckStarted ? timeFormatter(truckStarted) : 'N/A'
          )}
        </Box>
        <Box
          className={`${classes.etaSubContainer} ${classes.etaSubContainerWithBorder}`}
          data-test-id="eta-status-container"
        >
          {renderETADetails(
            'ETATrucks.requestedTime',
            renderRequestedTimeBlock(),
            <Box display="flex" alignItems="top">
              <Typography
                variant="body1"
                className={classes.etaSubContainerTitle}
                sx={{marginRight: '4px'}}
              >
                {t('ETATrucks.ETA')}
              </Typography>
              {getETAStatus()}
            </Box>
          )}
        </Box>
      </Box>
      {t('ETATrucks.etaDelayTerms') !== 'ETATrucks.etaDelayTerms' ? (
        <AlertWithLeftBorder message={t('ETATrucks.etaDelayTerms')} type="info" />
      ) : null}
    </Box>
  )
}
