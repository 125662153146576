import {useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'
import {api} from '../../App.global'
import {PageNames} from '../../common/constants'

export const useETA = (
  isETAVisible: boolean,
  pageName: PageNames.ORDERS | PageNames.ORDER_DETAILS,
  orderId?: string,
  deliveryId?: string
) => {
  if (pageName === PageNames.ORDERS)
    return useQuery(
      ['orderMaterialLocation', {orderId}],
      async () => {
        const res: AxiosResponse = await api.get(`/orders/${orderId}/materialLocations`)
        return res.data.find((delivery) => delivery.deliveryId === deliveryId)
      },
      {enabled: isETAVisible}
    )

  if (pageName === PageNames.ORDER_DETAILS)
    return useQuery(
      ['deliveryMaterialLocation', {deliveryId}],
      async () => {
        const res: AxiosResponse = await api.get(`/deliveries/${deliveryId}/materialLocations`)
        return res.data
      },
      {enabled: isETAVisible}
    )
}
