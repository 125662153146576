import {Typography} from '@hconnect/uikit'
import {LinearProgress} from '@material-ui/core'
import {makeStyles, withStyles} from '@material-ui/core/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  label: {
    flex: 0,
    marginBottom: 11
  },
  indicator: {
    width: 240
  },
  body2: {
    fontWeight: 500,
    fontSize: 12
  },
  colorSecondary: {
    color: 'rgba(255, 255, 255, 0.6)'
  }
}))

const HCLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: 'rgba(41, 170, 255, 0.4)'
  },
  barColorPrimary: {
    backgroundColor: 'rgb(41, 170, 255)'
  }
})(LinearProgress)

export const LoadMoreIndicator: React.FC<{onLight?: boolean}> = ({
  onLight = false,
  ...otherProps
}) => {
  const classes = useStyles()
  const {t} = useTranslation()
  return (
    <div data-test-id="pagination-loading-indicator" className={classes.root} {...otherProps}>
      <Typography
        variant="body2"
        color={onLight ? 'primary' : 'secondary'}
        classes={{
          body2: classes.body2,
          colorSecondary: classes.colorSecondary
        }}
        className={classes.label}
      >
        {t('loadMoreIndicator')}
      </Typography>
      <HCLinearProgress className={classes.indicator} />
    </div>
  )
}
