import {trackEvent} from '@hconnect/common/logging/Analytics'
import {BreakPoint} from '@hconnect/uikit/src/lib/Hooks/useBreakPoints'
import {Box} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory, useLocation} from 'react-router'
import {v4 as uuidV4} from 'uuid'

import {C60LeadBanner} from './components'
import {useLeadSubscriptions} from './hooks'
import {LeadAnalyticsType, LeadSubscriptionsEnum} from './types'

interface C60LeadType {
  customerId: string
  analytics: LeadAnalyticsType
  fullScreen: boolean
  countryCode: string
  isMediumScreen: boolean
  screenSize: BreakPoint
  contextualC60Widget?: boolean
  totalGrossValue?: number
  entryPoint?: string
}

export const C60Lead: React.FC<C60LeadType> = ({
  customerId,
  analytics,
  fullScreen,
  countryCode,
  isMediumScreen,
  screenSize,
  contextualC60Widget,
  totalGrossValue,
  entryPoint
}) => {
  const {t} = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const analyticsId = uuidV4()
  const page = location.pathname.split('/')[1]
  const onButtonClick = (page: string) => {
    trackEvent('hubC60WidgetClick', {
      analyticsId,
      userId: analytics.userId,
      customerId: analytics.customerId,
      countryCode,
      entryPoint,
      page
    })
    history.push(`/partners/c60?analyticsId=${analyticsId}&page=${page}`)
  }

  const onHover = (page: string) => {
    trackEvent('hubC60WidgetHover', {
      analyticsId,
      userId: analytics.userId,
      customerId: analytics.customerId,
      countryCode,
      entryPoint,
      page
    })
  }

  const onMediumSizeScreens = () => {
    trackEvent('hubC60WidgetMediumSize', {
      analyticsId,
      userId: analytics.userId,
      customerId: analytics.customerId,
      countryCode,
      entryPoint,
      page
    })
  }

  const {data, isFetching, isError} = useLeadSubscriptions(customerId)

  React.useEffect(() => {
    if (data?.status === LeadSubscriptionsEnum.canSubscribe) {
      trackEvent('hubC60WidgetLoggedInUserWithPermission', {
        analyticsId,
        customerId,
        userId: analytics.userId,
        countryCode,
        entryPoint,
        page
      })
    }
  }, [analytics.userId, analyticsId, customerId, data])

  React.useEffect(() => {
    if (isMediumScreen) {
      onMediumSizeScreens()
    }
    trackEvent('hubC60WidgetDisplay', {
      analyticsId,
      customerId,
      userId: analytics.userId,
      screenSize,
      countryCode,
      entryPoint,
      page
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMediumScreen, screenSize])
  if (!data || isError || isFetching) {
    return null
  }

  return (
    <Box mb={fullScreen ? 1.5 : 0}>
      <C60LeadBanner
        isSubscribed={data?.status === LeadSubscriptionsEnum.subscribed}
        canSubscribe={data?.status === LeadSubscriptionsEnum.canSubscribe}
        expired={data?.status === LeadSubscriptionsEnum.expired}
        onHover={() => onHover(page)}
        onButtonClick={() => onButtonClick(page)}
        t={t}
        contextualC60Widget={contextualC60Widget}
        totalGrossValue={totalGrossValue}
      />
    </Box>
  )
}
