import {Box, Button, StyledEngineProvider} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {ReactComponent as DismissIcon} from '../../../Assets/customerFeedbackBanner/crossIcon.svg'
import {ReactComponent as BannerIcon} from '../../../Assets/customerFeedbackBanner/customerFeedbackBanner.svg'
import {ReactComponent as RightArrow} from '../../../Assets/right-arrow.svg'
import {useImprovementProgrammeMutation, useBannerVisibility} from '../hooks'

import {useStyles} from './styles'
import {useTrackEventFeedbackProgramme} from '../hooks/useTrackEventFeedbackProgramme'
import {CustomerFeedbackProgrammeAction} from '../../../common/constants'
import {useRoles} from '../../../Roles'

interface Type {
  setKnowMore: (val: boolean) => void
  entryPoint?: string
}

export const CustomerFeedbackBanner: React.FC<Type> = ({setKnowMore, entryPoint}) => {
  const {classes} = useStyles()
  const {t} = useTranslation()
  const {data: roles} = useRoles()
  const [isBannerVisible, setIsBannerVisible] = useState<boolean>(true)
  const {mutate} = useImprovementProgrammeMutation()
  const {trackAnalytics} = useTrackEventFeedbackProgramme(roles)

  const handleKnowMore = (): void => {
    setKnowMore(true)
  }
  const handleDismiss = (): void => {
    setIsBannerVisible(false)
    setKnowMore(false) // set false to 1/3 view
    mutate({state: CustomerFeedbackProgrammeAction.DISMISS})
    trackAnalytics(entryPoint, CustomerFeedbackProgrammeAction.DISMISS)
  }

  if (!useBannerVisibility() || !isBannerVisible) return null

  return (
    <StyledEngineProvider injectFirst>
      <div className={classes.container} data-test-id="customer-feedback-banner">
        <div className={classes.mobileContainerBadge}>
          {t('customerFeedbackBanner.mobileBadge')}
        </div>
        <div className={classes.titleContainer}>
          <div className={classes.bannerIcon}>
            <BannerIcon />
          </div>
          <div className={classes.title}>{t('customerFeedbackBanner.title')}</div>
          <div className={classes.description}>{t('customerFeedbackBanner.description')}</div>
        </div>
        <Box className={classes.buttonContainer}>
          <Button
            variant="contained"
            onClick={handleKnowMore}
            startIcon={<RightArrow />}
            className={classes.knowMoreButton}
            data-test-id="feedback-banner-button-know-more"
          >
            {t('customerFeedbackBanner.knowMoreButton')}
          </Button>

          <Button
            variant="text"
            onClick={handleDismiss}
            startIcon={<DismissIcon />}
            className={classes.dismissButton}
            data-test-id="feedback-banner-button-dismiss"
          >
            {t('customerFeedbackBanner.dismissButton')}
          </Button>
        </Box>
      </div>
    </StyledEngineProvider>
  )
}
