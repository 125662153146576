import classNames from 'classnames'
import React from 'react'
import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
  contentContainer: {
    background: 'transparent',
    marginLeft: 'auto',
    color: theme.palette.primary.contrastText,
    height: '100%',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      background:
        'linear-gradient(90deg, rgba(0,55,77,1) 0%, rgba(0,99,138,1) 40%,rgba(0,99,138,1) 60%, rgba(0,55,77,1) 100%)'
    }
  }
}))

interface ContentProps {
  className?: string
  style?: React.CSSProperties
  children?: React.ReactNode
  onClick?: () => void
}

const Content: React.FC<ContentProps> = ({children, className, onClick, ...props}) => {
  const {
    classes: {contentContainer}
  } = useStyles()
  return (
    <div className={classNames(contentContainer, className)} {...props} onClick={onClick}>
      {children}
    </div>
  )
}

export default Content
