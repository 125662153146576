import {Theme} from '@mui/material'
import {makeStyles} from 'tss-react/mui'

import bannerBackground from '../../../Assets/customerFeedbackBanner/bannerBackground.svg'
import bannerBackgroundMobile from '../../../Assets/customerFeedbackBanner/bannerBgMobile.svg'

export const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    padding: theme.spacing(2),
    backgroundColor: '#3e87bb',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      backgroundImage: `url(${bannerBackgroundMobile})`,
      borderRadius: '8px',
      flexDirection: 'column'
    },
    [theme.breakpoints.up('md')]: {
      backgroundImage: `url(${bannerBackground})`,
      flexDirection: 'row',

      borderRadius: 'initial'
    },
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    color: '#fff',
    position: 'relative'
  },
  mobileContainerBadge: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '24px',
    width: '108px',
    position: 'absolute',
    top: '-12px',
    zIndex: 1,
    right: '12px',
    borderRadius: '4px',
    color: '#fff',
    fontSize: '12px',
    background: '#4ABABA',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  titleContainer: {
    display: 'grid',
    [theme.breakpoints.down('sm')]: {
      gridRowGap: theme.spacing(1),
      gridTemplateColumns: '50px auto',
      gridTemplateAreas: '"icon title" "description description"',
      marginBottom: theme.spacing(2),
      marginRight: 0
    },
    [theme.breakpoints.up('md')]: {
      marginRight: '12px',
      gridTemplateAreas: '"icon title" "icon description"'
    }
  },
  bannerIcon: {
    gridArea: 'icon',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',
    marginRight: theme.spacing(2)
  },
  title: {gridArea: 'title', fontWeight: 600, fontSize: '16px'},
  description: {gridArea: 'description', fontWeight: 400, fontSize: '12px'},
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    fontSize: '12px',
    '& button': {
      textTransform: 'none',
      height: '38px'
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      justifyContent: 'start'
    }
  },
  knowMoreButton: {
    backgroundColor: '#fff',
    color: '#016ad4',
    width: 'max-content',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: '#f5f5f5'
    }
  },
  dismissButton: {
    marginLeft: theme.spacing(2),
    fontWeight: 400,
    color: '#fff',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)'
    }
  }
}))
