import React from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {selectInvoices, setFilter} from '../../Organisms/Invoices'

import SupplierNameFilter from './SupplierNameFilter'
import {useSupplierNamesFromCustomer} from './useGetSupplierName'
import {RootState} from '../../App.reducer'
import {PageNames} from '../../common/constants'

interface SupplierNameFilterInvoicesType {
  customerId?: string
}

const SupplierNameFilterInvoices: React.FC<SupplierNameFilterInvoicesType> = ({customerId}) => {
  const {
    data: supplierNames,
    error: supplierNamesError,
    isLoading: supplierNamesIsLoading
  } = useSupplierNamesFromCustomer(customerId || '')
  const dispatch = useDispatch()
  const {supplierFilterNames} = useSelector((state: RootState) => selectInvoices(state).filters)

  const setSelectedOrgUnits = (orgUnitIds: string, selectedList: string[]) => {
    const filterValue = selectedList.length === supplierNames.length ? '' : orgUnitIds
    dispatch(setFilter('orgUnitIds', filterValue))
    dispatch(setFilter('supplierFilterNames', selectedList))
  }

  if (!supplierNames || supplierNamesError || supplierNamesIsLoading) return null
  return (
    <SupplierNameFilter
      supplierNames={supplierNames}
      setSelectedOrgUnits={setSelectedOrgUnits}
      selectedSuppliers={supplierFilterNames}
      page={PageNames.FINANCE_PAGE}
    />
  )
}

export default SupplierNameFilterInvoices
