import {Button, Theme} from '@mui/material'
import {makeStyles} from 'tss-react/mui'

export const styles = makeStyles()((theme: Theme) => ({
  button: {
    width: '100%',
    borderRadius: 6,
    backgroundColor: '#FFFFFF',
    height: 48,
    color: theme.palette.primary.main,
    textTransform: 'none',
    border: '1px solid rgba(0, 39, 77, 0.1)',
    padding: '13px 40px 13px 40px',
    cursor: 'pointer',
    textWrap: 'nowrap',

    '&.MuiButton-outlinedPrimary:hover': {
      color: theme.palette.primary.main
    },
    '&.MuiButton-outlinedPrimary:active, &.MuiButton-outlinedPrimary:focus': {
      border: '1px solid rgba(0, 39, 77, 0.1)',
      backgroundColor: '#FFFFFF',
      color: theme.palette.primary.main
    }
  }
}))

type IOutlinedButton = {
  value: string
  dataTestId?: string
  onClick: (event) => void
  classNames?: string
  startIcon: any
}

export const OutlinedButton = ({
  value,
  dataTestId,
  startIcon,
  classNames,
  onClick
}: IOutlinedButton) => {
  const {classes} = styles()
  return (
    <Button
      variant="outlined"
      className={`${classNames} ${classes.button}`}
      color="primary"
      startIcon={startIcon}
      onClick={(e) => onClick(e)}
      data-test-id={dataTestId}
    >
      {value}
    </Button>
  )
}
